.msnt-preload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #000;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    pointer-events: all;
}.msnt-preload.loaded {
    opacity: 0;
    visibility: hidden;
    -webkit-transition-delay: .4s;
            transition-delay: .4s;
    pointer-events: none;
}.msnt-preload svg {
    fill: #fff;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    height: auto;
    opacity: 0;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
}.msnt-preload.loading svg {
    opacity: 1;
}
/*# sourceMappingURL=preload.css.map */