@import "../flags.css";@import "../v2/image-changer.css";.pageContacts h1,
.pageContacts h2 {
    text-align: left;
    text-transform: none;
    font-size: 48px;
    line-height: 1.2;
}.pageContacts h2 {
    margin-bottom: 35px;
}.pageContacts h1 span,
.pageContacts h2 span {
    display: block;
}.pageContacts .content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 75px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
}.pageContacts p {
    font-size: 14px;
}.pageContacts .contact_reasons {
    padding: 75px 75px 45px 75px;
}.pageContacts .reason-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: auto;
    margin: -4px;
    max-width: 800px;
}.pageContacts .reason-list li {
    width: calc(50% - 8px);
    max-width: 340px;
    margin: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background-color: #ededed;
    padding: 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
    -webkit-transition: background 200ms linear;
    transition: background 200ms linear;
}.pageContacts .reason-list li:hover {
    background-color: #cdcdcd;
}.pageContacts .contact-block {
    background-color: #ededed;
    max-height: 0;
    overflow: hidden;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 45px;
    will-change: max-height, padding;
    -webkit-transition: max-height 300ms linear, padding 300ms linear, margin 300ms linear;
    transition: max-height 300ms linear, padding 300ms linear, margin 300ms linear;
    position: relative;
    z-index: 1;
}.pageContacts .contact-block.active {
    max-height: 600px;
    padding: 45px;
    margin-bottom: 0;
}.pageContacts .contact-block .contact-form_wrapper {
    position: relative;
}.pageContacts .contact-block .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
}.pageContacts .contact-block .close:before,
.pageContacts .contact-block .close:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: #000;
    -webkit-transition: background 200ms linear;
    transition: background 200ms linear;
    left: 50%;
}.pageContacts .contact-block .close:hover:before,
.pageContacts .contact-block .close:hover:after {
    background: #f00;
}.pageContacts .contact-block .close:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}.pageContacts .contact-block .close:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}.pageContacts .contact-block h2 {
    text-align: center;
}.pageContacts .form-wrapper {
    max-width: 300px;
    margin: 0 auto;
}.pageContacts .styledForm .field {
    display: block;
    width: 100%;
    margin: 0;
}.pageContacts .styledForm .g-recaptcha {
    margin-top: 30px;
}.pageContacts .styledForm input {
    margin-top: 20px;
    line-height: 25px;
}.pageContacts .sendBtn {
    text-align: center;
}.pageContacts .sendBtn button {
    border: 2px solid #000;
    outline: 0;
    background: transparent;
    font-size: 20px;
    font-weight: 600;
    padding: 7px 25px;
    cursor: pointer;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}.pageContacts .sendBtn button:hover {
    background: #000;
    color: #fff;
}.pageContacts .addresses-block {
    background: #f9f9f9;
}.pageContacts .addresses-block h2 {
    padding-top: 90px;
}.pageContacts .addresses-block .main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    width: auto;
    margin-bottom: 50px;
    gap: 6px;
}.pageContacts .addresses-block .item {
    width: calc(50% - 3px);
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageContacts .addresses-block .item-global {
    background: #000;
    color: #fff;
}.pageContacts .addresses-block .item:nth-child(n + 2) {
    background: #ededed;
}.pageContacts .addresses-block .map-image {
    width: 100%;
    height: 100%;
    min-height: 235px;
}.pageContacts .addresses-block .map-image a {
    width: 100%;
    height: 100%;
    display: block;
    background-position: center center;
    background-size: cover;
}.pageContacts .addresses-block .ms-flag {
    width: 60px;
    min-width: 60px;
    height: 36px;
}.pageContacts .addresses-block .contact-info {
    margin-left: 10px;
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 250px;
    max-width: 250px;
    min-width: 250px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    gap: 10px;
}.pageContacts .contact-info_bottom {
    margin-top: auto;
}.pageContacts .addresses-block .contact-info p {
    font-size: 17px;
    font-weight: 600;
}.pageContacts .addresses-block .contact-info span {
    font-size: 14px;
}.pageContacts .addresses-block .ms-flag--svg {
    width: 36px;
    min-width: 36px;
}.pageContacts .addresses-block .contact-info svg {
    width: 100%;
    height: 100%;
}.pageContacts .addresses-block .contact-info a {
    font-size: 14px;
    color: #3bb0ff;
    display: block;
}.pageContacts .address-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: -10px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}.pageContacts .address-list li {
    width: calc(50% - 20px);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 10px;
    border: 1px solid #eeeeee;
    background-color: #fff;
    -webkit-transition: border-color 200ms linear;
    transition: border-color 200ms linear;
}.pageContacts .address-list li.active {
    border: 1px solid #909090;
}.pageContacts .address-list .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
    padding: 20px;
}.pageContacts .address-list .top .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}.pageContacts .address-list .top h3 {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 20px;
}.pageContacts .address-list .top svg {
    display: block;
    width: 19px;
    height: 19px;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
    -webkit-transition: -webkit-transform 200ms linear;
    transition: -webkit-transform 200ms linear;
    transition: transform 200ms linear;
    transition: transform 200ms linear, -webkit-transform 200ms linear;
}.pageContacts .address-list .active .top svg {
    -webkit-transform: rotateZ(270deg);
            transform: rotateZ(270deg);
}.pageContacts .address-list .bottom {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 200ms linear, padding 200ms linear;
    transition: max-height 200ms linear, padding 200ms linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 0 0 60px;
}.pageContacts .address-list .active .bottom {
    max-height: 200px;
    padding: 10px 0 30px 60px;
}.pageContacts .address-list .bottom .left,
.pageContacts .address-list .bottom .right {
    padding: 0 40px;
    font-size: 12px;
}.pageContacts .address-list .bottom .right {
    border-left: 3px solid #535353;
    padding-right: 20px;
}.pageContacts .address-list .bottom a {
    color: #3bb0ff;
    display: block;
}@media screen and (max-width: 1024px) {
    .pageContacts .content {
        padding: 0 45px;
    }

    .pageContacts .contact_reasons {
        padding: 45px;
    }

    .pageContacts .addresses-block .contact-info {
        padding: 25px;
    }

    .pageContacts .addresses-block .main {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    .pageContacts .addresses-block .item {
        width: 100%;
    }

    .pageContacts .addresses-block .ms-flag {
        width: 50px;
        min-width: 50px;
        height: 30px;
    }

    .pageContacts .address-list .bottom .left {
        padding-left: 30px;
        padding-right: 20px;
    }

    .pageContacts .address-list .bottom .right {
        padding-left: 20px;
        padding-right: 10px;
    }
}@media screen and (max-width: 859px) {
    .pageContacts .address-list li {
        width: calc(100% - 20px);
    }
}@media screen and (max-width: 767px) {
    .pageContacts .reason-list li {
        width: calc(50% - 8px);
        margin: 4px;
        padding: 10px;
    }

    .pageContacts .content {
        padding: 0 20px;
    }

    .pageContacts .contact_reasons {
        padding: 60px 20px 20px 20px;
    }

    .pageContacts .addresses-block h2 {
        padding-top: 50px;
    }

    .pageContacts .addresses-block .contact-info {
        padding: 15px;
    }

    .pageContacts .addresses-block .map-image {
        width: 45%;
        min-width: 45%;
    }

    .pageContacts h1,
    .pageContacts h2 {
        font-size: 36px;
    }

    .pageContacts .addresses-block .item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .pageContacts .addresses-block .map-image {
        width: 100%;
        min-width: 100%;
    }

    .pageContacts .addresses-block .contact-info {
        margin: 10px 0 0 0;
    }
}@media screen and (max-width: 500px) {
    .pageContacts .address-list .bottom {
        padding: 0 0 0 10px;
    }

    .pageContacts .address-list .active .bottom {
        padding: 10px 0 30px 10px;
    }

    .pageContacts .address-list .bottom .left {
        padding-left: 10px;
    }
}
/*# sourceMappingURL=contacts.css.map */