#header {
  position: fixed;
  top: var(--msnt-header-position-top);
  left: var(--msnt-header-position-left);
  right: var(--msnt-header-position-right);
  padding: 10px 20px;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #fff;
  border-radius: var(--msnt-border-radius);
  -webkit-transition: background 300ms linear;
  transition: background 300ms linear;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
          box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}body.live-edit #header {
  top: 30px;
}body.menuVisible #header {
  background: var(--msnt-menu-background);
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}#header:before {
  content: '';
  position: absolute;
  display: none;
  top: calc(100% - var(--msnt-header-bg-position-bottom) - 10px);
  left: calc(var(--msnt-menu-width) + var(--msnt-header-bg-position-left) - 10px);
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: calc(var(--msnt-border-radius) * 3) 0;
  border: calc(var(--msnt-border-radius) * 2) solid var(--msnt-menu-background);
  border-right: 0;
  border-bottom: 0;
  -webkit-transform: translateX(calc((var(--msnt-menu-width) + 40px) * -1));
          transform: translateX(calc((var(--msnt-menu-width) + 40px) * -1));
  -webkit-transition: -webkit-transform 0.25s linear;
  transition: -webkit-transform 0.25s linear;
  transition: transform 0.25s linear;
  transition: transform 0.25s linear, -webkit-transform 0.25s linear;
}.menuVisible #header:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}.menuVisible #header:after {
  content: '';
  display: block;
  position: absolute;
  top: var(--msnt-header-bg-position-top);
  bottom: var(--msnt-header-bg-position-bottom);
  left: var(--msnt-header-bg-position-left);
  right: var(--msnt-header-bg-position-right);
  background: var(--msnt-menu-background);
  border-radius: var(--msnt-menu-border-radius);
}.menuVisible #header>* {
  z-index: 1;
}.menuVisible #logo svg {
  fill: #fff;
}#logo_motorsport {
  fill: #000;
}.menuVisible #logo_motorsport {
  fill: #fff;
}#logo_network {
  fill: #fff;
}.menuVisible #logo_network {
  fill: #f00;
}#logo_network_bar_transparent {
  fill: #f00;
}.menuVisible #logo_network_bar_transparent {
  fill: #fff;
}.menuVisible #open-menu-btn {
  fill: #fff;
}#open-menu-btn {
  position: relative;
  width: 36px;
  height: 36px;
  z-index: 200;
  cursor: pointer;
  display: block;
  top: 1px;
  fill: #000;
  -webkit-transition: fill 0.15s linear;
  transition: fill 0.15s linear;
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}#open-menu-btn svg {
  width: 100%;
  height: 100%;
}#open-menu-btn .transform-a,
#open-menu-btn .transform-b {
  -webkit-transform: rotateZ(0) translate(0, 0);
          transform: rotateZ(0) translate(0, 0);
  -webkit-transition: -webkit-transform 0.15s linear;
  transition: -webkit-transform 0.15s linear;
  transition: transform 0.15s linear;
  transition: transform 0.15s linear, -webkit-transform 0.15s linear;
}#open-menu-btn .hiddeble {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}.menuVisible #open-menu-btn .transform-a {
  -webkit-transform: rotate(45deg) translate(7px, -3px);
          transform: rotate(45deg) translate(7px, -3px);
}.menuVisible #open-menu-btn .transform-b {
  -webkit-transform: rotate(-45deg) translate(-17px, 10px);
          transform: rotate(-45deg) translate(-17px, 10px);
}.menuVisible #open-menu-btn .hiddeble {
  opacity: 0;
}#logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 38px;
}#logo svg {
  display: block;
  height: 100%;
}#logo,
#logo svg,
#logo svg text {
  text-decoration: none;
}#header #portal-btn {
  background: #ff0000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  visibility: hidden;
  width: 42px;
  padding: 0;
}@media screen and (min-width: 450px) {
  #header:before {
    display: block;
  }
}@media screen and (min-width: 768px) {
  #header {
    padding: 11px 18px;
  }

  #logo {
    height: 44px;
  }

  #open-menu-btn {
    width: 42px;
    height: 42px;
    top: 1px;
  }
}[data-page="home"] #header,
[data-page="pageBrandGuidelines"] #header,
[data-page="pageContacts"] #header,
[data-page="pageNews"] #header {
  background: var(--msnt-header-background);
}[data-page="home"] #header #logo_motorsport,
[data-page="pageBrandGuidelines"] #header #logo_motorsport,
[data-page="pageContacts"] #header #logo_motorsport,
[data-page="pageNews"] #header #logo_motorsport {
  fill: #fff;
}[data-page="home"] #header #open-menu-btn,
[data-page="pageBrandGuidelines"] #header #open-menu-btn,
[data-page="pageContacts"] #header #open-menu-btn,
[data-page="pageNews"] #header #open-menu-btn {
  fill: var(--msnt-menu-icon-color);
}#header.invert {
  background: var(--msnt-header-invert-background);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
          box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}#header.invert #logo_motorsport {
  fill: #000;
}.menuVisible #header.invert #logo_motorsport {
  fill: #fff;
}#header.invert #open-menu-btn {
  fill: var(--msnt-menu-invert-icon-color);
}.menuVisible #header.invert #open-menu-btn {
  fill: #fff;
}
/*# sourceMappingURL=header.css.map */