@import "./badge-colors.css";.msnt-slides {
  opacity: 0;
  height: 100%;
  overflow: auto;
  min-width: 100%;
}.msnt-slides::-webkit-scrollbar {
  display: none;
}.msnt-slides:not(.transition) {
  -ms-scroll-snap-type: mandatory;
      scroll-snap-type: mandatory;
  -ms-scroll-snap-points-y: repeat(100%);
      scroll-snap-points-y: repeat(100%);
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}.msnt-slide {
  height: 100vh;
  width: 100%;
  margin-top: 0;
  padding: 0 !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  scroll-snap-align: start;
  background: #000;
}.msnt-slides.transition .msnt-slide {
  height: 100%;
}.msnt-slide:nth-child(1) {
  margin-top: 0 !important;
}.msnt-slide:nth-last-child(1) {
  height: 100% !important;
}.msnt-slide:nth-last-child(1) .msnt-slides_wrapper {
  position: relative;
}.msnt-slide .msnt-slides_wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 58px 0 30px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1;
  background-color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}.msnt-slide .msnt-slide_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  overflow: hidden;
  z-index: 1;
}.msnt-slide_bg-image {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(0);
          filter: brightness(0);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  -webkit-transform: scale(1);
          transform: scale(1);
  will-change: transform, filter;
  /* transition: transform 0ms linear 150ms, filter 150ms linear; */
}.msnt-slide_bg-image.loaded {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(1);
          filter: brightness(1);
}.msnt-slide_current .msnt-slide_bg-image {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(1);
          filter: brightness(1);
  will-change: transform, filter;
  -webkit-transition: -webkit-transform 10s linear 1s, -webkit-filter 150ms linear;
  transition: -webkit-transform 10s linear 1s, -webkit-filter 150ms linear;
  transition: transform 10s linear 1s, filter 150ms linear;
  transition: transform 10s linear 1s, filter 150ms linear, -webkit-transform 10s linear 1s, -webkit-filter 150ms linear;
}.msnt-slide.msnt-slide__blured .msnt-slide_bg-image {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(0);
          filter: brightness(0);
}.msnt-slide--About-Us .msnt-slide_bg-image {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.75" /><feFuncG type="linear" slope="0.75" /><feFuncB type="linear" slope="0.75" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: grayscale(1) brightness(0.75);
          filter: grayscale(1) brightness(0.75);
}.msnt-slide--About-Us.msnt-slide__blured .msnt-slide_bg-image {
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: grayscale(1) brightness(0);
          filter: grayscale(1) brightness(0);
}.msnt-slide--Driven_Lifestyle.msnt-slide .msnt-slide-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}.msnt-slide_bg-image.loaded.threejs {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: none;
  transition: none;
  min-width: 100%;
  min-height: 100%;
}.msnt-slide_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  color: #fff;
  max-width: 1600px;
  min-height: 60%;
  z-index: 10;
}.msnt-slide_badge {
  margin-bottom: 10px;
}.msnt-slide_badge a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 19px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: color 0.4s linear;
  transition: color 0.4s linear;
}.msnt-slide_badge-icon {
  border-radius: 50%;
  background: transparent;
  color: #fff;
  display: inline-block;
  padding: 5px;
  margin-left: 10px;
  -webkit-transition: color 0.4s linear, -webkit-box-shadow 0.4s linear;
  transition: color 0.4s linear, -webkit-box-shadow 0.4s linear;
  transition: box-shadow 0.4s linear, color 0.4s linear;
  transition: box-shadow 0.4s linear, color 0.4s linear, -webkit-box-shadow 0.4s linear;
}.msnt-slide_badge a:hover {
  color: #fff;
}.msnt-slide_badge a:hover .msnt-slide_badge-icon {
  -webkit-box-shadow: inset 0 0 0 15px #fff;
          box-shadow: inset 0 0 0 15px #fff;
}.msnt-slide_badge-icon::before {
  content: '\E608';
  position: relative;
  top: 1px;
}.msnt-slide_infographic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 0 10%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}.msnt-slide_infographic--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}.msnt-slide_title {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  max-width: 90%;
}.msnt-slide_title--animated {
  position: relative;
  display: inline-block;
  color: transparent;
}.msnt-slide_title a {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-size: 28px;
}.msnt-slide_title img {
  display: none;
}.msnt-slide_title-slogan {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
}.msnt-slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
}.msnt-slide_infographic--inline .msnt-slide-item {
  margin-top: 15px;
}.msnt-slide-item_digit {
  font-size: 45px;
  margin-left: 15px;
}.msnt-slide-item_text {
  font-family: 'ClanProWide';
  font-size: 12px;
  line-height: 1.3;
  margin-left: 15px;
  text-shadow: 0 0 6px #000;
}.msnt-slide-item_image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}.msnt-slide_infographic--inline .msnt-slide-item_text {
  margin-left: 15px;
}.msnt-slide_text {
  font-family: 'ClanProWide';
  font-size: 14px;
  line-height: 1.5;
  width: 85%;
  margin: 0 auto;
  text-align: center;
  text-shadow: 0 0 6px #000;
}.msnt-slide_text p {
  display: inline;
}.msnt-slide_infographic .msnt-slide-item+.msnt-slide-item {
  margin-top: 10px;
}@media screen and (max-width: 1023px) {
  .msnt-slide--About-Us .msnt-slide_infographic--inline .msnt-slide-item_image {
    width: 50%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
  }

  .msnt-slide--About-Us .msnt-slide_infographic--inline .msnt-slide-item_digit {
    width: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .msnt-slide--About-Us .msnt-slide_infographic--inline .msnt-slide-item_text {
    max-width: 100%;
    width: 50%;
  }
}@media screen and (max-width: 767px) {
  .msnt-slide--About-Us .msnt-slide_infographic--inline .msnt-slide-item_digit {
    width: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .msnt-slide_infographic {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .msnt-slide-item_text {
    width: 180px;
  }
}@supports (display: grid) and (max-width: 767px) {
  .msnt-slide_infographic .msnt-slide-item {
    display: grid;
    grid-template-columns: 110px 1fr;
    width: 100%;
  }

  .msnt-slide-item_text {
    width: auto;
  }
}@media (min-width: 768px) {
  .msnt-slide_title {
    letter-spacing: -4px;
  }

  .msnt-slide_infographic {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .msnt-slide_infographic .msnt-slide-item {
    width: 33.3333%;
  }

  .msnt-slide_badge {
    margin-bottom: 20px;
  }

  .msnt-slide {
    padding: 66px 0 50px 0;
  }

  .msnt-slide-item_digit {
    font-size: 60px;
  }

  .msnt-slide_text {
    font-size: 24px;
  }

  .msnt-slide_bg {
    overflow: hidden;
  }

  .msnt-slide_title a,
  .msnt-slide_title {
    font-size: 52px;
  }

  .msnt-slide_content {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 45%;
  }

  .msnt-slide_infographic {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 100%;
  }

  .msnt-slide_infographic .msnt-slide-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }

  .msnt-slide_infographic .msnt-slide-item+.msnt-slide-item {
    margin-top: 0;
  }

  .msnt-slide_infographic--inline .msnt-slide-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-align: left;
    margin-top: 0;
    margin-left: 15px;
  }

  .msnt-slide_infographic--inline .msnt-slide-item+.msnt-slide-item {
    margin-left: 20px;
  }

  .msnt-slide-item_text {
    line-height: 1.3;
  }

  .msnt-slide_title img {
    display: inline-block;
    margin: 0 auto;
    max-width: 600px;
  }

  .msnt-slide_title-slogan {
    font-size: 28px;
    font-weight: 700;
  }

  .msnt-slide_title-slogan br {
    display: none;
  }

  .msnt-slide_text p {
    display: block;
    text-align: center;
  }
}@media screen and (orientation: landscape) and (max-height: 500px) {

  .msnt-slide_title a,
  .msnt-slide_title {
    font-size: 32px;
  }

  .msnt-slide_badge {
    margin-bottom: 10px;
  }

  .msnt-slide_infographic {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  .msnt-slide_infographic--inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .msnt-slide_infographic .msnt-slide-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 33.3333%;
  }

  .msnt-slide_infographic .msnt-slide-item_text {
    text-align: center;
  }

  .msnt-slide_infographic--inline .msnt-slide-item_text {
    font-size: 11px;
  }

  .msnt-slide-item_text {
    margin-left: 0;
  }

  .msnt-slide_text {
    margin-top: 10px;
    font-size: 14px !important;
  }

  .msnt-slide_content {
    padding: 58px 0 30px 0;
  }

  .msnt-slide_title-slogan br {
    display: none;
  }

  .msnt-slide_title img {
    display: none !important;
  }
}@media (orientation: landscape) and (min-width: 768px) {
  .msnt-slide_text p {
    display: inline;
  }

  .msnt-slide_title a,
  .msnt-slide_title {
    font-size: 32px;
  }

  .msnt-slide_text {
    margin-top: 10px;
  }
}@media (min-width: 1024px) {

  .msnt-slide_title a,
  .msnt-slide_title {
    font-size: 71px;
  }

  .msnt-slide_title img {
    max-width: 830px;
  }

  .msnt-slide-item_digit {
    font-size: 72px;
  }

  .msnt-slide-item_text {
    font-size: 14px;
    margin-left: 0;
  }

  .msnt-slide_infographic--inline {
    width: 80%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .msnt-slide--Driven_Lifestyle.msnt-slide .msnt-slide-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}@media (orientation: landscape) and (min-width: 1024px) {
  .msnt-slide_content {
    min-height: 60%;
  }
}@media (min-width: 1366px) {

  .msnt-slide_title a,
  .msnt-slide_title {
    font-size: 95px;
  }

  .msnt-slide_title-slogan {
    font-size: 46px;
  }

  .msnt-slide_title img {
    max-width: 870px;
  }

  .msnt-slide-item_digit {
    font-size: 84px;
  }

  .msnt-slide_infographic {
    margin-bottom: 20px;
  }

  .msnt-slide-item_text {
    font-size: 16px;
    margin-left: 0;
  }

  .msnt-slide_text {
    font-size: 32px;
    font-family: 'ClanProWideRegular';
  }
}@media screen and (max-width: 450px) {
  .msnt-slide--Motorsport_Total .msnt-slide_title a {
    font-size: 24px;
  }
}
/*# sourceMappingURL=slides.css.map */