.pageCareer h2 {
    text-align: center;
    font-size: 50px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 35px;
}.pageCareer .hidden {
    display: none;
}.pageCareer .content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 50px 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}#msnt-vacancy-block .msnt-vacancy-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
}#msnt-vacancy-block .msnt-vacancy-list a {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #f3f3f3;
    min-height: 80px;
    padding: 30px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    gap: 10px;
    text-decoration: none;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    color: #000;
}#msnt-vacancy-block .msnt-vacancy-list a:hover {
    background: #e4e4e4;
}#msnt-vacancy-block .msnt-vacancy-list .BambooHR-ATS-Title {
    font-size: 20px;
    font-family: 'Nexa';
    text-decoration: underline;
}#msnt-vacancy-block .msnt-vacancy-list .BambooHR-ATS-Location {
    font-size: 20px;
    text-align: center;
}#msnt-vacancy-block .msnt-vacancy-list .BambooHR-ATS-Origin {
    font-size: 20px;
    font-family: 'Nexa';
    font-style: normal;
    text-align: right;
}#msnt-vacancy-block .msnt-vacancy-block--empty {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #7e7e7e;
}@media screen and (max-width: 767px) {
    .pageCareer h2 {
        font-size: 28px;
    }

    .pageContacts .content {
        padding: 30px 20px;
    }

    #msnt-vacancy-block .msnt-vacancy-list a {
        grid-template-columns: 1fr;
    }

    #msnt-vacancy-block .msnt-vacancy-list a>* {
        text-align: center !important;
        margin: 10px;
    }
}#opportunities {
    padding-top: 10px;
}#opportunities .wrapper {
    max-width: 375px;
    margin: 0 auto;
}#opportunities .item {
    position: relative;
    padding-bottom: 25px;
    padding-top: 10px;
}#opportunities .item:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border: 4px solid #ccc;
    border-top-width: 0;
    border-right-width: 0;
    position: absolute;
    right: 30px;
    bottom: -30px;
}#opportunities .item:nth-child(even):after {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    left: 30px;
    right: auto;
}#opportunities .number {
    font-size: 90px;
    font-family: 'Nexa-Regular';
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
    line-height: 1.2;
    position: relative;
}#opportunities .number:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: #000;
}#opportunities .text {
    text-align: center;
    line-height: 1.2;
}#opportunities .text h3 {
    font-family: var(--msnt-secondary-font-family);
    font-size: 20px;
    text-transform: uppercase;
    max-width: 135px;
    margin: 25px auto 20px auto;
}#opportunities .text p {
    max-width: 250px;
    margin: 0 auto;
    font-size: 14px;
}#opportunities+.content {
    padding-top: 0;
    margin-top: 10px;
}@media screen and (min-width: 768px) {
    #opportunities {
        margin-top: 50px;
        padding-top: 50px;
    }

    #opportunities+.content {
        margin-top: 100px;
    }

    #opportunities .wrapper {
        max-width: 1240px;
    }

    #opportunities .item {
        display: grid;
        grid-template-columns: 4fr 6fr;
        -webkit-box-align: stretch;
            -ms-flex-align: stretch;
                align-items: stretch;
    }

    #opportunities .number {
        max-width: 100%;
        margin: 0;
        padding-right: 85px;
        font-size: 220px;
        line-height: 0.75;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: end;
    }

    #opportunities .number span {
        position: relative;
        top: 11px;
    }

    #opportunities .number:after {
        width: 4px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    #opportunities .item+.item {
        margin-top: 180px;
    }

    #opportunities .item:after {
        width: 40px;
        height: 40px;
        right: 0;
        bottom: -40px;
        border-width: 10px;
        border-top-width: 0;
        border-right-width: 0;
    }

    #opportunities .item:nth-child(even) {
        grid-template-columns: 6fr 4fr;
    }

    #opportunities .item:nth-child(even) .number {
        padding-right: 0;
        padding-left: 85px;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start;
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
    }

    #opportunities .item:nth-child(even) .number:after {
        right: auto;
        left: 0;
    }

    #opportunities .item:nth-child(even):after {
        left: 0;
        right: auto;
    }

    #opportunities .item:nth-child(even) .text {
        padding-left: 0;
        padding-right: 85px;
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        justify-self: end;
    }

    #opportunities .text {
        text-align: left;
        text-align: initial;
        padding-left: 85px;
        max-width: 500px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    #opportunities .text h3 {
        font-size: 36px;
        max-width: 100%;
        margin: 0;
    }

    #opportunities .text p {
        font-size: 16px;
        margin: 10px 0 0 0;
        max-width: 100%;
    }
}
/*# sourceMappingURL=career.css.map */