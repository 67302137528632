/* ####### RESET CSS ####### */html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline; 
    font-size:100%;
}body {
    line-height: 1;
}ol, ul, li {
    list-style: none;
}blockquote, q {
    quotes: none;
}:focus {
    outline: 0;
}ins {
    text-decoration: none;
}del {
    text-decoration: line-through;
}table {
    border-collapse: collapse;
    border-spacing: 0;
}/* ####### AND RESET CSS ####### */
/*# sourceMappingURL=reset.css.map */