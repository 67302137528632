.msnt-menu {
  position: fixed;
  top: var(--msnt-menu-top);
  left: var(--msnt-menu-left);
  bottom: var(--msnt-menu-bottom);
  max-width: calc(100vw - var(--msnt-menu-left) - var(--msnt-menu-left));
  z-index: 41;
  width: 100%;
  background: var(--msnt-menu-background);
  -webkit-transform: translateX(calc((100% + var(--msnt-menu-left)) * -1));
          transform: translateX(calc((100% + var(--msnt-menu-left)) * -1));
  -webkit-transition: -webkit-transform 0.25s linear;
  transition: -webkit-transform 0.25s linear;
  transition: transform 0.25s linear;
  transition: transform 0.25s linear, -webkit-transform 0.25s linear;
  overflow: hidden;
  border-radius: var(--msnt-menu-border-radius);
}.msnt-menu_content {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding-right: 0;
}.menuVisible .msnt-menu {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}.msnt-menu_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.7;
  z-index: 40;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}.menuVisible .msnt-menu_overlay {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}.msnt-menu_top {
  width: 0;
  background-color: var(--msnt-menu-background);
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 100;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px 20px;
  height: 78px;
}.msnt-menu_top .close-menu-btn {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}.msnt-menu_top .close-menu-btn svg {
  fill: #fff;
}.menuVisible .msnt-menu_top {
  width: 100%;
}.msnt-menu_wrapper {
  height: calc(100% - 78px);
  width: calc(100% + 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 50px 50px 0 20px;
}.live-edit .msnt-menu_top {
  height: 99px;
}.live-edit .msnt-menu_wrapper {
  height: calc(100% - 88px);
}.msnt-menu_items {
  width: 100%;
}.msnt-about_dropdown-menu {
  margin-bottom: 15px;
  display: none;
}.msnt-menu_about li,
.msnt-menu_items li {
  line-height: 1.5;
}.msnt-menu_items li,
.msnt-menu_about>li {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}.msnt-menu_items a {
  color: var(--msnt-menu-item-color);
  text-decoration: none;
}.msnt-menu_items.msnt-about_dropdown-menu li {
  line-height: 0;
}.msnt-menu_items .msnt-about_dropdown-menu li a {
  font-size: 60%;
  text-transform: none;
  line-height: 1.5;
  font-weight: 600;
}.msnt-about_dropdown-menu .division-section {
  padding-right: 20px;
  border-bottom: 2px solid #fff;
}.msnt-about_dropdown-menu .division-section li a {
  display: block;
  padding: 5px 15px;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
}.msnt-about_dropdown-menu .division-section+.division-section {
  margin-top: 15px;
}.msnt-about_dropdown-menu .division-section ul {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 20px;
}.msnt-about_dropdown-menu .division-section li:hover a {
  color: #000;
  background: #fff;
}.msnt-about_dropdown-menu .division-section+.division-section {
  margin-top: 15px;
}.msnt-about_dropdown-menu .division-section .division-title {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .5px;
  display: inline-block;
  padding: 5px 0;
  cursor: default;
  font-size: 60%;
  line-height: 1.5;
}.msnt-about_dropdown-menu li a:hover {
  border: 0;
}.msnt-about_dropdown-menu a {
  color: #fff;
  text-decoration: none;
  font-family: 'ClanPro Wide News';
}.msnt-about_dropdown-menu a:hover {
  border-bottom: 1px solid #fff;
}.msnt-menu_other-social {
  margin-top: 20px;
}.msnt-menu_other-social .social {
  width: 28px;
  height: 28px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  -webkit-transition: background 0.15s ease-out;
  transition: background 0.15s ease-out;
}.msnt-menu_other-social .social a {
  color: #fff;
  line-height: 28px;
  font-size: 18px;
  text-decoration: none;
  outline: none;
  display: block;
  width: 28px;
  height: 28px;
  -webkit-transition: color 0.15s ease-out;
  transition: color 0.15s ease-out;
}.msnt-menu_other-social .social a svg {
  padding: 2px;
}.msnt-menu_other-social .social+.social {
  margin-left: 5px;
}.msnt-menu_other-copyright {
  color: #fff;
  font-size: 13px;
  margin-top: 15px;
  cursor: default;
  margin-bottom: 20px;
}.msnt-menu_other-copyright span {
  font-weight: 700;
}.msnt-about_dropdown-arrow {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  position: relative;
  left: 10px;
  bottom: 5px;
  -webkit-transform: rotate(45deg) translateX(0) translateY(0);
          transform: rotate(45deg) translateX(0) translateY(0);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  cursor: pointer;
}.msnt-about_dropdown-arrow.arrow-up {
  -webkit-transform: rotate(-135deg) translateX(-5px) translateY(-5px);
          transform: rotate(-135deg) translateX(-5px) translateY(-5px);
}.header-link {
  position: relative;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  display: inline-block;
  line-height: 0.9;
  will-change: transform;
}.header-link:hover {
  -webkit-transform: rotateX(90deg) translateY(22px);
          transform: rotateX(90deg) translateY(22px);
}.header-link span {
  display: inline-block;
  -webkit-transition: inherit;
  transition: inherit;
  will-change: opacity;
}.header-link--hover {
  bottom: 100%;
  color: var(--msnt-menu-item-color-hover);
  left: 0;
  opacity: 0;
  position: absolute;
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: inherit;
  transition: inherit;
  width: 100%;
}.header-link:hover .header-link--hover {
  opacity: 1;
}.header-link:hover span {
  opacity: 0;
}@media (min-width: 450px) {
  .msnt-menu {
    max-width: var(--msnt-menu-width) !important;
  }
}@media (min-width: 768px) {
  .msnt-menu_wrapper {
    height: calc(100% - 86px);
  }

  .msnt-menu_top {
    height: 86px;
  }

  .msnt-menu_wrapper {
    padding: 0 50px 0 20px;
    position: relative;
  }

  .live-edit .msnt-menu_top {
    height: 96px;
  }

  .live-edit .msnt-menu_wrapper {
    height: calc(100% - 96px);
  }
}.msnt-fade-in-animation {
  -webkit-animation: fade-in-up 0.5s ease-in-out 0s 1 normal;
          animation: fade-in-up 0.5s ease-in-out 0s 1 normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: opacity, transform;
}.msnt-fade-out-animation {
  -webkit-animation: fade-in-down 0.25s ease-in-out 0s 1 normal;
          animation: fade-in-down 0.25s ease-in-out 0s 1 normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: opacity, transform;
}@-webkit-keyframes fade-in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}@keyframes fade-in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}@-webkit-keyframes fade-in-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh);
  }
}@keyframes fade-in-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh);
  }
}
/*# sourceMappingURL=menu.css.map */