.msnt-slide_badge[data-color="#ff0000"] a,
.msnt-slide_badge[data-color="#ff0000"]:hover .msnt-slide_badge-icon {
    color: #ff0000;
}.msnt-slide_badge[data-color="#ff0000"] .msnt-slide_badge-icon {
    background: #ff0000;
}.msnt-slide_badge[data-color="#ffdd00"] a,
.msnt-slide_badge[data-color="#ffdd00"]:hover .msnt-slide_badge-icon {
    color: #ffdd00;
}.msnt-slide_badge[data-color="#ffdd00"] .msnt-slide_badge-icon {
    background: #ffdd00;
    color: #000;
}.msnt-slide_badge[data-color="#007eff"] a,
.msnt-slide_badge[data-color="#007eff"]:hover .msnt-slide_badge-icon {
    color: #007eff;
}.msnt-slide_badge[data-color="#007eff"] .msnt-slide_badge-icon {
    background: #007eff;
}.msnt-slide_badge[data-color="#00aaff"] a,
.msnt-slide_badge[data-color="#00aaff"]:hover .msnt-slide_badge-icon {
    color: #00aaff;
}.msnt-slide_badge[data-color="#00aaff"] .msnt-slide_badge-icon {
    background: #00aaff;
}.msnt-slide_badge[data-color="#fc0b2a"] a,
.msnt-slide_badge[data-color="#fc0b2a"]:hover .msnt-slide_badge-icon {
    color: #fc0b2a;
}.msnt-slide_badge[data-color="#fc0b2a"] .msnt-slide_badge-icon {
    background: #fc0b2a;
}.msnt-slide_badge[data-color="#f4c403"] a,
.msnt-slide_badge[data-color="#f4c403"]:hover .msnt-slide_badge-icon {
    color: #f4c403;
}.msnt-slide_badge[data-color="#f4c403"] .msnt-slide_badge-icon {
    background: #f4c403;
    color: #000;
}.msnt-slide_badge[data-color="#f6ff00"] a,
.msnt-slide_badge[data-color="#f6ff00"]:hover .msnt-slide_badge-icon {
    color: #f6ff00;
}.msnt-slide_badge[data-color="#f6ff00"] .msnt-slide_badge-icon {
    background: #f6ff00;
    color: #000;
}.msnt-slide_badge[data-color="#00b1ff"] a,
.msnt-slide_badge[data-color="#00b1ff"]:hover .msnt-slide_badge-icon {
    color: #00b1ff;
}.msnt-slide_badge[data-color="#00b1ff"] .msnt-slide_badge-icon {
    background: #00b1ff;
}.msnt-slide_badge[data-color="#00cbfe"] a,
.msnt-slide_badge[data-color="#00cbfe"]:hover .msnt-slide_badge-icon {
    color: #00cbfe;
}.msnt-slide_badge[data-color="#00cbfe"] .msnt-slide_badge-icon {
    background: #00cbfe;
}.msnt-slide_badge[data-color="#f9f100"] a,
.msnt-slide_badge[data-color="#f9f100"]:hover .msnt-slide_badge-icon {
    color: #f9f100;
}.msnt-slide_badge[data-color="#f9f100"] .msnt-slide_badge-icon {
    background: #f9f100;
    color: #000;
}.msnt-slide_badge[data-color="#ffc000"] a,
.msnt-slide_badge[data-color="#ffc000"]:hover .msnt-slide_badge-icon {
    color: #ffc000;
}.msnt-slide_badge[data-color="#ffc000"] .msnt-slide_badge-icon {
    background: #ffc000;
    color: #000;
}.msnt-slide_badge[data-color="#47c700"] a,
.msnt-slide_badge[data-color="#47c700"]:hover .msnt-slide_badge-icon {
    color: #47c700;
}.msnt-slide_badge[data-color="#47c700"] .msnt-slide_badge-icon {
    background: #47c700;
    color: #000;
}.msnt-slide_badge[data-color="#bf0013"] a,
.msnt-slide_badge[data-color="#bf0013"]:hover .msnt-slide_badge-icon {
    color: #bf0013;
}.msnt-slide_badge[data-color="#bf0013"] .msnt-slide_badge-icon {
    background: #bf0013;
    color: #000;
}.msnt-slide_badge[data-color="#008ad8"] a,
.msnt-slide_badge[data-color="#008ad8"]:hover .msnt-slide_badge-icon {
    color: #008ad8;
}.msnt-slide_badge[data-color="#008ad8"] .msnt-slide_badge-icon {
    background: #008ad8;
    color: #000;
}.msnt-slide_badge[data-color="#ffba00"] a,
.msnt-slide_badge[data-color="#ffba00"]:hover .msnt-slide_badge-icon {
    color: #ffba00;
}.msnt-slide_badge[data-color="#ffba00"] .msnt-slide_badge-icon {
    background: #ffba00;
}.msnt-slide_badge[data-color="#7200ff"] a,
.msnt-slide_badge[data-color="#7200ff"]:hover .msnt-slide_badge-icon {
    color: #7200ff;
}.msnt-slide_badge[data-color="#7200ff"] .msnt-slide_badge-icon {
    background: #7200ff;
}.msnt-slide_badge[data-color="#ff7000"] a,
.msnt-slide_badge[data-color="#ff7000"]:hover .msnt-slide_badge-icon {
    color: #ff7000;
}.msnt-slide_badge[data-color="#ff7000"] .msnt-slide_badge-icon {
    background: #ff7000;
}.msnt-slide_badge[data-color="#00ff0a"] a,
.msnt-slide_badge[data-color="#00ff0a"]:hover .msnt-slide_badge-icon {
    color: #00ff0a;
}.msnt-slide_badge[data-color="#00ff0a"] .msnt-slide_badge-icon {
    background: #00ff0a;
}.msnt-slide_badge[data-color="#00cbff"] a,
.msnt-slide_badge[data-color="#00cbff"]:hover .msnt-slide_badge-icon {
    color: #00cbff;
}.msnt-slide_badge[data-color="#00cbff"] .msnt-slide_badge-icon {
    background: #00cbff;
}.msnt-slide_badge[data-color="#6200ff"] a,
.msnt-slide_badge[data-color="#6200ff"]:hover .msnt-slide_badge-icon {
    color: #6200ff;
}.msnt-slide_badge[data-color="#6200ff"] .msnt-slide_badge-icon {
    background: #6200ff;
}.msnt-slide_badge[data-color="#3bfd00"] a,
.msnt-slide_badge[data-color="#3bfd00"]:hover .msnt-slide_badge-icon {
    color: #3bfd00;
}.msnt-slide_badge[data-color="#3bfd00"] .msnt-slide_badge-icon {
    background: #3bfd00;
}.msnt-slide_badge[data-color="#fd5d00"] a,
.msnt-slide_badge[data-color="#fd5d00"]:hover .msnt-slide_badge-icon {
    color: #fd5d00;
}.msnt-slide_badge[data-color="#fd5d00"] .msnt-slide_badge-icon {
    background: #fd5d00;
}
/*# sourceMappingURL=badge-colors.css.map */