.pageAdvertise {
    padding-top: 75px;
}.pageAdvertise .content {
    max-width: 800px;
    padding: 40px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 auto;
}.pageAdvertise h1,
.pageAdvertise h2 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 32px;
    line-height: 50px;
    color: #000000;
    margin-bottom: 30px;
}.pageAdvertise h2 span {
    display: block;
}.pageAdvertise h3 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    color: #000000;
    letter-spacing: 0.08em;
}.pageAdvertise h4 {
    font-family: ClanPro;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}.pageAdvertise h5 {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    font-weight: 600;
    position: relative;
    margin-top: 40px;
    text-transform: uppercase;
}.pageAdvertise h5:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 40px;
    height: 1px;
    background-color: #000000;
    margin-top: 2px;
}.pageAdvertise p,
.pageAdvertise li {
    font-family: ClanPro;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin: 25px 0;
    letter-spacing: 0.02em;
}.pageAdvertise .text ul,
.pageAdvertise .events-list {
    margin: 20px 0;
    padding-left: 20px;
}.pageAdvertise li {
    margin: 0;
    list-style: disc;
}.pageAdvertise img,
.pageAdvertise svg {
    width: 100%;
    height: auto;
    max-height: 100%;
}.pageAdvertise .center {
    text-align: center;
}.pageAdvertise .dots {
    border-bottom: 1px solid #5E5E5E;
    height: 40px;
    width: 95%;
    margin: 0 auto 25px auto;
}.pageAdvertise .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}.pageAdvertise .item {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}.pageAdvertise .grid.numbers .item {
    height: 210px;
    text-align: center;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageAdvertise .grid.podcast {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    margin-top: 55px;
}.pageAdvertise .grid.podcast .item:nth-child(n+3) {
    margin-top: 50px;
}.pageAdvertise .grid.grid-brands {
    padding-left: 30px;
    margin-top: 40px;
}.pageAdvertise .grid.grid-brands .item {
    width: auto;
    padding: 10px;
    min-width: 100px;
    min-height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}.pageAdvertise .grid.grid-brands .item img {
    width: auto;
}.pageAdvertise .item.item-inline {
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    text-align: left;
}.pageAdvertise .item-inline .img {
    min-width: 80px;
}.pageAdvertise .visitors .item-inline {
    width: 100%;
    max-width: 600px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 80px;
}.pageAdvertise .visitors .item-inline .img {
    min-width: 214px;
    padding: 20px;
}.pageAdvertise .item-inline .text {
    padding: 0 20px;
}.pageAdvertise .formats .item,
.pageAdvertise .overview .item,
.pageAdvertise .videos_wrapper .item {
    width: 33.3333%;
}.pageAdvertise .formats .item {
    text-align: center;
    height: 215px;
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageAdvertise .videos {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    padding: 0 10px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
}.pageAdvertise .videos .item {
    height: auto;
    width: auto;
}.pageAdvertise .videos .text {
    margin-top: 15px;
}.pageAdvertise .videos_wrapper {
    padding: 0 0 0 25px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageAdvertise .videos_wrapper+.text {
    padding: 0 0 0 30px;
}.pageAdvertise .videos_wrapper .item {
    width: 33.3333%;
    padding: 0 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}.pageAdvertise .videos .first {
    width: 100%;
}.pageAdvertise .videos .item.first {
    width: 135px;
    min-width: 135px;
    padding-right: 30px;
    border-right: dotted 1px #686868;
}.pageAdvertise .print {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}.pageAdvertise .print .text {
    padding-left: 25px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageAdvertise .print .first .text {
    padding-right: 25px;
    border-right: dotted 1px #686868;
}.pageAdvertise .print .item svg {
    height: 150px;
}.pageAdvertise .overview .item {
    height: 240px;
    text-align: center;
}.pageAdvertise .overview .text {
    margin-top: 20px;
}.pageAdvertise .videos_wrapper .text {
    width: 100%;
    text-align: left;
}.pageAdvertise .impact .item {
    width: auto;
    padding: 0 10px;
}.pageAdvertise .item b {
    display: block;
}.pageAdvertise .item p {
    margin: 0;
}.pageAdvertise .iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 10px;
}.pageAdvertise .iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}.pageAdvertise .network-scope {
    max-width: 600px;
    margin: 0 auto;
    display: block;
}.pageAdvertise .events-logos img {
    max-width: 512px;
    margin: 0 auto;
    display: block;
    margin-top: -50px;
}.pageAdvertise .events-list {
    padding-left: 60px;
}.pageAdvertise .trade-event-logos {
    max-width: 467px;
    margin: 0 auto;
    display: block;
}.pageAdvertise .insider {
    display: block;
}/* FORM */.pageAdvertise form {
    width: 100%;
    display: inline-block;
}.pageAdvertise .contactAddressWrappper {
    width: 100%;
}.pageAdvertise .styledForm .field {
    display: block;
    width: auto;
    max-width: 335px;
}.pageAdvertise .styledForm .field.wide {
    max-width: 100%;
}.pageAdvertise .styledForm .field+.field {
    margin-left: 0;
    margin-top: 20px;
}.pageAdvertise .styledForm .field input {
    padding: 10px 0;
}.pageAdvertise .styledForm .sendBtn {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    width: 100px;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 45px;
    line-height: 23px;
    border-radius: 45px;
}.pageAdvertise .styledForm select {
    padding: 10px 0;
    width: 100%;
    border: 2px solid #000;
    color: #000;
    background: transparent;
    font-size: 14px;
    line-height: 1.5;
    font-family: ClanPro;
    border-radius: 0;
}/* MEDIA */@media screen and (max-width: 600px) {

    .pageAdvertise h1,
    .pageAdvertise h2 {
        font-size: 16px;
        margin: 15px 0;
    }

    .pageAdvertise .dots {
        height: 0px;
        margin-bottom: 35px;
    }

    .pageAdvertise .dots span {
        margin: 0 1px;
        width: 5px;
        height: 5px;
    }

    .pageAdvertise h2 span {
        display: inline;
    }

    .pageAdvertise h3 {
        font-size: 20px;
        margin: 15px 0;
    }

    .pageAdvertise h4 {
        font-size: 12px;
    }

    .pageAdvertise p,
    .pageAdvertise li {
        font-size: 12px;
        margin: 15px 0;
        line-height: 18px;
    }

    .pageAdvertise li {
        margin: 5px 0;
    }

    .pageAdvertise .grid.numbers .item {
        min-height: 150px;
        height: auto;
    }

    .pageAdvertise .grid.numbers .item svg {
        width: 60px;
        height: 60px;
    }

    .pageAdvertise .grid.numbers .item h3 {
        margin-bottom: 0;
    }

    .pageAdvertise .grid.formats .item {
        width: 50%;
        height: 165px;
        padding: 10px;
    }

    .pageAdvertise .grid.formats svg {
        max-width: 150px;
    }

    .pageAdvertise .overview {
        margin-bottom: 35px;
    }

    .pageAdvertise .overview .item {
        width: 50%;
        height: auto;
        min-height: 185px;
    }

    .pageAdvertise .overview .item h3 {
        margin-bottom: 0;
    }

    .pageAdvertise .overview .text {
        margin-top: 0;
    }

    .pageAdvertise .overview svg {
        max-width: 100px;
    }

    .pageAdvertise .videos {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        padding: 0;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
    }

    .pageAdvertise .videos .item.first {
        width: 100%;
        min-width: 100%;
        border: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 0;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
    }

    .pageAdvertise .videos .item>svg {
        max-width: 95px;
        min-width: 95px;
        height: 75px;
    }

    .pageAdvertise .videos .item.first .text {
        margin: 0 0 20px 10px;
    }

    .pageAdvertise .videos_wrapper {
        padding: 0;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        position: relative;
        left: -5px;
        right: -5px;
    }

    .pageAdvertise .videos_wrapper .text {
        margin-top: 0;
    }

    .pageAdvertise .videos_wrapper .item {
        padding: 0;
        width: auto;
        margin: 0 5px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .pageAdvertise .videos_wrapper+.text {
        padding: 0;
    }

    .pageAdvertise .impact {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 10px;
    }

    .pageAdvertise .impact .item {
        width: 100%;
        max-width: 125px;
    }

    .pageAdvertise .grid.podcast {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .pageAdvertise .grid.podcast .item {
        width: 100%;
    }

    .pageAdvertise .grid.podcast .item:not(:nth-child(1)) {
        margin-top: 45px;
    }

    .pageAdvertise .print {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .pageAdvertise .print .item {
        width: 100%;
    }

    .pageAdvertise .print .item+.item {
        margin-top: 40px;
    }

    .pageAdvertise .print .item .text {
        padding: 0;
        border: 0;
    }

    .pageAdvertise .print .item svg {
        max-height: 80px;
        max-width: 140px;
        height: auto;
        margin-bottom: 10px;
    }

    .pageAdvertise .events-list {
        padding-left: 35px;
    }

    .pageAdvertise .visitors .item-inline {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .pageAdvertise .item-inline .text {
        padding: 0;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .styledForm .styledBtn.sendBtn {
        padding: 5px 10px;
        line-height: 33px;
    }
}
/*# sourceMappingURL=advertise.css.map */