#page_wrapper.pageAboutUs {
    background-color: #000;
}.revealed #page_wrapper.pageAboutUs {
    background-color: transparent;
}#page_wrapper.pageAboutUs .headerBanner {
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
}.revealed #page_wrapper.pageAboutUs .headerBanner {
    opacity: 1;
}[data-page="aboutUs"] .corporateContent {
    margin: 0 20px;
    padding: 40px 20px;
    font-family: ClanPro;
    text-align: left;
    font-size: 18px;
    opacity: 0;
    max-width: 1080px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    background: #fff;
    position: relative;
}[data-page="aboutUs"] .corporateContent .nobr {
    white-space: nowrap;
}[data-page="aboutUs"] .corporateContent p {
    margin: 22px 0;
    line-height: 1.3;
    font-weight: 500;
    text-align: left;
}[data-page="aboutUs"] .corporateContent p:first-child {
    margin-top: 0;
}[data-page=aboutUs] .corporateContent a {
    text-decoration: underline;
    font-weight: 600;
    color: #000;
}[data-page="aboutUs"] .corporateContent ul {
    padding-left: 20px;
}[data-page="aboutUs"] .corporateContent li {
    margin: 15px 0;
    list-style: disc;
}.revealed [data-page="aboutUs"] .corporateContent {
    opacity: 1;
}.parallax-group {
    padding-top: 100px;
    opacity: 0;
}.parallax-group img {
    max-width: 100%;
}.revealed .parallax-group {
    opacity: 1;
}.pageAboutUs .corporateContent h1 {
    font-size: 46px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
    line-height: 1;
    font-family: Montserrat;
}.pageAboutUs .contentWrapper {
    padding-top: 30px;
    background: #fff;
    color: #000;
    opacity: 0;
    max-width: 1080px;
    margin: 0 20px;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}.revealed .pageAboutUs .contentWrapper {
    opacity: 1;
    z-index: 5;
}.pageAboutUs .contentWrapper h1,
.pageAboutUs .contentWrapper h2 {
    font-size: 46px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
    line-height: 1;
}.pageAboutUs .contentWrapper h2 {
    font-size: 40px;
}.pageAboutUs .contentWrapper h2~h2 {
    margin-top: 75px;
}.pageAboutUs .contentWrapper .promo {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
}.pageAboutUs .contentWrapper .promo p {
    font-size: 23px;
    line-height: 1.5;
    font-family: ClanPro;
}.pageAboutUs .brands {
    font-size: 0;
    line-height: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 15px;
    margin: -30px auto 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}.pageAboutUs .brands .brand {
    display: block;
    line-height: 1.2;
    margin-bottom: 1%;
    font-family: ClanPro;
    cursor: pointer;
}.pageAboutUs #footer {
    margin-top: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    position: relative;
}.revealed .pageAboutUs #footer {
    opacity: 1;
}.pageAboutUs .brands .brand,
.pageAboutUs .brands .grid-sizer {
    width: calc(50% - 30px);
    margin: 15px;
}.pageAboutUs .brands .gutter-sizer {
    width: 30px;
}.pageAboutUs .brands .content {
    padding: 25px;
    border: none !important;
    text-align: center;
}.pageAboutUs .brand.selected .content {
    min-height: 200px;
    padding: 40px 25px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageAboutUs .brands .brand {
    overflow: hidden;
}.pageAboutUs .brands .brand.selected,
.pageAboutUs .brands .brand:hover {
    overflow: visible;
}.pageAboutUs .brands .brand.selected~.brand .content {
    border-left-color: #d3d3d3;
}.pageAboutUs .wrapper {
    overflow: hidden;
    height: 150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 15%;
}.pageAboutUs .selected .wrapper {
    height: auto;
    padding: 0;
}.brand .content {
    position: relative;
}.brand .content::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    /*background-size: auto 100%;*/
    background-size: cover;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    border-radius: var(--msnt-border-radius);
    z-index: -1;
}.brand.latPhotographic .content::after {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.6" /><feFuncG type="linear" slope="0.6" /><feFuncB type="linear" slope="0.6" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: grayscale(1) brightness(0.6);
            filter: grayscale(1) brightness(0.6);
}.brand.autosport .content::after {
    background-image: url(../../src/img/our-brands/images/small/autosport_v4.jpg);
}.selected.autosport .content::after {
    background-image: url(../../src/img/our-brands/images/wide/autosport_v4.jpg);
}.brand.autosportAwards .content::after {
    background-image: url(../../src/img/our-brands/images/small/autosport_awards_v2.jpg);
}.selected.autosportAwards .content::after {
    background-image: url(../../src/img/our-brands/images/wide/autosport_awards_v2.jpg);
}.brand.autosportInternational .content::after {
    background-image: url(../../src/img/our-brands/images/small/autosport_international_v3.jpg);
}.selected.autosportInternational .content::after {
    background-image: url(../../src/img/our-brands/images/wide/autosport_international_v3.jpg);
}.brand.amalgamCollection .content::after {
    background-image: url(../../src/img/our-brands/images/small/amalgam_collection_v2.jpg);
}.selected.amalgamCollection .content::after {
    background-image: url(../../src/img/our-brands/images/wide/amalgam_collection_v2.jpg);
}.brand.f1Racing .content::after {
    background-image: url(../../src/img/our-brands/images/small/f1_racing.jpg);
}.selected.f1Racing .content::after {
    background-image: url(../../src/img/our-brands/images/wide/f1_racing.jpg);
}.brand.gpRacing .content::after {
    background-image: url(../../src/img/our-brands/images/small/gp_racing.jpg);
}.selected.gpRacing .content::after {
    background-image: url(../../src/img/our-brands/images/wide/gp_racing.jpg);
}.brand.ferrariChat .content::after {
    background-image: url(../../src/img/our-brands/images/small/ferrari_chat_v2.jpg);
}.selected.ferrariChat .content::after {
    background-image: url(../../src/img/our-brands/images/wide/ferrari_chat_v2.jpg);
}.brand.motorsportTower .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_tower.jpg);
}.selected.brand.motorsportTower .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_tower.jpg);
}.brand.sothebysMotorsport .content::after {
    background-image: url(../../src/img/our-brands/images/small/sothebys_motorsport.jpg);
}.selected.brand.sothebysMotorsport .content::after {
    background-image: url(../../src/img/our-brands/images/wide/sothebys_motorsport.jpg);
}.brand.latPhotographic .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_images_v3.jpg);
}.selected.latPhotographic .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_images_v3.jpg);
}.brand.motorsportImages .content::after {
    background-image: url(../../src/img/our-brands/images/small/lat_images.jpg);
}.brand.motorsportImages .content::after {
    background-image: url(../../src/img/our-brands/images/wide/lat_images.jpg);
}.brand.giorgioPiola .content::after {
    background-image: url(../../src/img/our-brands/images/small/giorgio_piola_v2.jpg);
}.selected.giorgioPiola .content::after {
    background-image: url(../../src/img/our-brands/images/wide/giorgio_piola_v2.jpg);
}.brand.motor1 .content::after {
    background-image: url(../../src/img/our-brands/images/small/motor1_v3.jpg);
}.selected.motor1 .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motor1_v3.jpg);
}.brand.motorsport .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_v3.jpg);
}.selected.motorsport .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_v3.jpg);
}.brand.motorsportGaming .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_gaming.jpg);
}.selected.motorsportGaming .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_gaming.jpg);
}.brand.motorsportStats .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_stats_v5.jpg);
}.selected.motorsportStats .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_stats_v5.jpg);
}.brand.motorsportTv .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_tv_v4.jpg);
}.selected.motorsportTv .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_tv_v4.jpg);
}.brand.motorstore .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorstore.jpg);
}.selected.motorstore .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorstore.jpg);
}.brand.formulaE .content::after {
    background-image: url(../../src/img/our-brands/images/small/formula_e.jpg);
}.selected.formulaE .content::after {
    background-image: url(../../src/img/our-brands/images/wide/formula_e.jpg);
}.brand.rideApart .content::after {
    background-image: url(../../src/img/our-brands/images/small/ride_apart.jpg);
}.selected.rideApart .content::after {
    background-image: url(../../src/img/our-brands/images/wide/ride_apart.jpg);
}.brand.insideEvs .content::after {
    background-image: url(../../src/img/our-brands/images/small/inside_evs_v4.jpg);
}.selected.insideEvs .content::after {
    background-image: url(../../src/img/our-brands/images/wide/inside_evs_v3.jpg);
}.brand.forix .content::after {
    background-image: url(../../src/img/our-brands/images/small/forix_v2.jpg);
}.selected.forix .content::after {
    background-image: url(../../src/img/our-brands/images/wide/forix_v2.jpg);
}.brand.autoclassic .content::after {
    background-image: url(../../src/img/our-brands/images/small/autoclassic.jpg);
}.selected.autoclassic .content::after {
    background-image: url(../../src/img/our-brands/images/wide/autoclassic2.jpg);
}.brand.msmarket .content::after {
    background-image: url(../../src/img/our-brands/images/small/msmarket2.jpg);
}.selected.msmarket .content::after {
    background-image: url(../../src/img/our-brands/images/wide/msmarket2.jpg);
}.brand.dukevideo .content::after {
    background-image: url(../../src/img/our-brands/images/small/dukevideo.jpg);
}.selected.dukevideo .content::after {
    background-image: url(../../src/img/our-brands/images/wide/dukevideo.jpg);
}.brand.motorsportjobs .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_jobs_v4.jpg);
}.selected.motorsportjobs .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_jobs_v4.jpg);
}.brand.motorgt .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorgt.jpg);
}.selected.motorgt .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorgt.jpg);
}.brand.motorsportExperiences .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_experiences_v2.jpg);
}.selected.motorsportExperiences .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_experiences_v2.jpg);
}.brand.motorsportLive .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_experiences_v2.jpg);
}.brand.motorsportLive .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_experiences_v2.jpg);
}.brand.lemansEsports .content::after {
    background-image: url(../../src/img/our-brands/images/small/lemans_esports_v4.jpg);
}.selected.lemansEsports .content::after {
    background-image: url(../../src/img/our-brands/images/wide/lemans_esports_v4.jpg);
}.brand.pitStopBetting .content::after {
    background-image: url(../../src/img/our-brands/images/small/pitstop_betting.jpg);
}.selected.pitStopBetting .content::after {
    background-image: url(../../src/img/our-brands/images/wide/pitstop_betting.jpg);
}.brand.motorsportGallery .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_gallery.jpg);
}.selected.motorsportGallery .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_gallery.jpg);
}.brand.motorsportPro .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_pro.jpg);
}.selected.motorsportPro .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_pro.jpg);
}.brand.motorsportGames .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_games_v2.jpg);
}.selected.motorsportGames .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_games_v2.jpg);
}.brand.motorsportMYEV .content::after {
    background-image: url(../../src/img/our-brands/images/small/myev_2.jpg);
}.selected.motorsportMYEV .content::after {
    background-image: url(../../src/img/our-brands/images/wide/myev_2.jpg);
}.brand.motorsportFans .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_fans.jpg);
}.selected.motorsportFans .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_fans.jpg);
}.brand.motorious .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorious.jpg);
}.selected.motorious .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorious.jpg);
}.brand.canossa .content::after {
    background-image: url(../../src/img/our-brands/images/small/canossa_v2.jpg);
}.selected.canossa .content::after {
    background-image: url(../../src/img/our-brands/images/wide/canossa_v2.jpg);
}.brand.dupontregistry .content::after {
    background-image: url(../../src/img/our-brands/images/small/dupontregistry.jpg);
}.selected.dupontregistry .content::after {
    background-image: url(../../src/img/our-brands/images/wide/dupontregistry.jpg);
}.brand.cavallino .content::after {
    background-image: url(../../src/img/our-brands/images/small/cavallino.jpg);
}.selected.cavallino .content::after {
    background-image: url(../../src/img/our-brands/images/wide/cavallino.jpg);
}.brand.autosport_podcast .content::after {
    background-image: url(../../src/img/our-brands/images/small/autosport_podcast.jpg);
}.selected.autosport_podcast .content::after {
    background-image: url(../../src/img/our-brands/images/wide/autosport_podcast.jpg);
}.brand.formel1 .content::after {
    background-image: url(../../src/img/our-brands/images/small/formel1.jpg);
}.selected.formel1 .content::after {
    background-image: url(../../src/img/our-brands/images/wide/formel1.jpg);
}.brand.motorsport_total .content::after {
    background-image: url(../../src/img/our-brands/images/small/motorsport_total.jpg);
}.selected.motorsport_total .content::after {
    background-image: url(../../src/img/our-brands/images/wide/motorsport_total.jpg);
}.pageAboutUs .brands .content svg {
    max-width: 300px;
    width: 100%;
    max-height: 75px;
    height: 100%;
}.pageAboutUs .brands .content svg.square {
    max-height: 150px;
}.pageAboutUs .brands .content svg,
.pageAboutUs .brands .content svg path,
.pageAboutUs .brands .content svg polygon,
.pageAboutUs .brands .content svg rect,
.pageAboutUs .brands .content svg ellipse {
    -webkit-transition: fill 0.2s linear;
    transition: fill 0.2s linear;
    will-change: fill;
}.pageAboutUs .brand:not(:hover):not(.selected) .content svg,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg path,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg polygon,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg rect,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg ellipse {
    fill: #fff !important;
}.pageAboutUs .brand:not(:hover):not(.selected) .content svg path.gray,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg polygon.gray,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg rect.gray,
.pageAboutUs .brand:not(:hover):not(.selected) .content svg ellipse.gray {
    fill: #666 !important;
}.pageAboutUs .brands .content img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="7" /><feFuncG type="linear" slope="7" /><feFuncB type="linear" slope="7" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: grayscale(1) brightness(7);
            filter: grayscale(1) brightness(7);
    -webkit-transition: -webkit-filter 0.2s linear;
    transition: -webkit-filter 0.2s linear;
    transition: filter 0.2s linear;
    transition: filter 0.2s linear, -webkit-filter 0.2s linear;
    will-change: filter;
    /*margin: 10% 0;*/
}.pageAboutUs .brands .autosportInternational .content img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: grayscale(1) brightness(1);
            filter: grayscale(1) brightness(1);
}.pageAboutUs .brands .gpRacing .content img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: grayscale(1) brightness(1);
            filter: grayscale(1) brightness(1);
}.pageAboutUs .brands .brand.selected .content img,
.pageAboutUs .brands .brand:hover .content img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: grayscale(0) brightness(1) !important;
            filter: grayscale(0) brightness(1) !important;
}.pageAboutUs .brands .description {
    display: none;
}.pageAboutUs .brands .content .closeBtn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    display: none;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}.pageAboutUs .brands .content .closeBtn:before {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    font-size: 20px;
}.pageAboutUs .brands .brand.selected {
    width: 100%;
    position: relative;
}.pageAboutUs .brands .brand.selected .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}.pageAboutUs .brands .brand.selected .wrapper {
    text-align: left;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}.pageAboutUs .brands .brand.selected .content .imgWrapper {
    width: 100%;
    max-width: calc(50% - 15px);
    padding: 0px 25px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-right: 1px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}@media screen and (min-width: 1024px) {
    [data-page="aboutUs"] .corporateContent {
        margin: 0 auto;
        padding: 60px 40px;
    }

    .pageAboutUs .contentWrapper {
        margin: 0 auto;
    }
}.pageAboutUs .brands .brand.selected .content .description {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-left: 30px;
    padding-right: 20px;
    width: 80%;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}.pageAboutUs .brands .content p,
.pageAboutUs .brands .content a {
    font-size: 14px;
}.pageAboutUs .brands .content p {
    color: #fff;
    text-shadow: 3px 3px 8px #000;
}.pageAboutUs .brands .brand.selected .content a {
    margin-top: 20px;
    color: red;
    text-decoration: none;
    display: block;
}.pageAboutUs .brands .brand.selected .content a:hover {
    text-decoration: underline;
}.pageAboutUs .brands .brand.selected .closeBtn {
    display: block;
}@media screen and (min-width: 768px) {}@media only screen and (max-width: 767px) {

    .pageAboutUs .contentWrapper h1,
    .pageAboutUs .corporateContent h1 {
        font-size: 28px;
        margin-bottom: 25px;
    }

    .pageAboutUs .contentWrapper h2 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .pageAboutUs .contentWrapper h2~h2 {
        margin-top: 55px;
    }

    .pageAboutUs .contentWrapper .promo p {
        display: inline;
        font-size: 18px;
    }

    .pageAboutUs .brands .content p,
    .pageAboutUs .brands .content a {
        font-size: 16px;
    }

    .pageAboutUs .brands .brand,
    .pageAboutUs .brands .grid-sizer {
        width: calc(50% - 20px);
        margin: 10px;
    }

    .pageAboutUs .brands .brand.selected .content .imgWrapper {
        max-width: calc(50% - 10px);
    }

    .pageAboutUs .wrapper {
        height: 120px;
    }

    .pageAboutUs .brand.selected .content {
        min-height: 120px;
    }

    .pageAboutUs .brands .content svg.square {
        max-height: 120px;
    }
}@media only screen and (max-width: 600px) {

    .pageAboutUs .brands .brand,
    .pageAboutUs .brands .grid-sizer,
    .pageAboutUs .brands .brand.selected {
        width: calc(100% - 20px);
        margin: 10px;
    }

    .pageAboutUs .brands .content {
        padding: 20px;
    }

    .pageAboutUs .brands .brand.selected .content .description {
        width: 100%;
    }

    .pageAboutUs .brands .gutter-sizer {
        width: 0;
    }

    .pageAboutUs .brands .content img {
        margin: 0;
    }

    .pageAboutUs .brands .brand.selected .content .imgWrapper {
        width: 100%;
        max-width: none;
        height: 120px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        border-right: none;
        border-bottom: 1px solid #fff;
        padding: 0 15% 20px 15%;
    }

    .pageAboutUs .brands .brand.selected .content .description {
        padding-left: 0;
        padding-top: 20px;
    }

    .pageAboutUs .brands .brand.selected .wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}@media only screen and (max-width: 350px) {
    .pageAboutUs .brands .content {
        padding: 10px;
    }
}
/*# sourceMappingURL=page_our-brands33.css.map */