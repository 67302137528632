.msnt-slide_title svg,
.msnt-slide-item svg {
  max-width: 84px;
  max-height: 84px;
  stroke: #fff;
  stroke-width: 2;
  fill: none;
  margin-right: 10px;
  width: auto;
  height: auto;
  opacity: 0;
}.msnt-slide_infographic--inline .msnt-slide-item svg {
  margin-right: 0;
}.msnt-slide-item svg.small {
  max-width: 50px;
  max-height: 50px;
}.msnt-slide-item svg.wide {
  max-width: 100% !important;
}.msnt-slide-item svg line,
.msnt-slide-item svg path,
.msnt-slide-item svg text,
.msnt-slide-item svg circle,
.msnt-slide-item svg polygon,
.msnt-slide-item svg polyline,
.msnt-slide-item svg ellipse {}.msnt-slide-item svg .stretcher {
  visibility: hidden;
}/* TRASH */.msnt-slide--Motorsport .msnt-slide_bg {
  opacity: 0.9;
}/* */@media screen and (max-height: 620px), (max-width: 767px) {

  .msnt-slide_infographic--inline,
  .msnt-slide_infographic {
    margin-top: 35px;
    margin-bottom: 15px;
  }

  .msnt-slide--Motorsport-Tv .msnt-slide_infographic {
    margin-top: 25px;
  }

  .msnt-slide_infographic .msnt-slide-item {
    margin: 10px 0;
  }

  .msnt-slide_infographic .msnt-slide-item svg {
    max-width: 60px;
    max-height: 60px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .msnt-slide_infographic .msnt-slide-item svg {
    max-width: 110px;
    max-height: 110px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .msnt-slide--Motorsport-Tv .msnt-slide_infographic .msnt-slide-item svg {
    max-width: 95px;
    max-height: 95px;
  }

  .msnt-slide-item_text {
    font-size: 14px !important;
    font-weight: 400;
  }

  .msnt-slide_infographic--inline .msnt-slide-item_text {
    max-width: 30%;
  }

  .msnt-slide_text p {
    font-size: 18px;
    line-height: 1.3;
  }

  .msnt-slide--Motorsport-Tv .msnt-slide_text {
    width: 100%;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  .msnt-slide--Motorsport-Tv .msnt-slide_text p {
    font-size: 16px;
  }

  .msnt-slide_infographic .msnt-slide-item+.msnt-slide-item {
    margin-top: 0;
  }

  .msnt-slide-item.msnt-mobile-optimize--normal {
    margin-bottom: 10px;
  }

  .msnt-slide--Motorsport-Tv .msnt-slide-item svg.wide {
    max-height: 80px;
  }
}@media (min-width: 768px) {
  .msnt-slide-item svg {
    max-width: 90px;
    max-height: 90px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .msnt-slide-item svg.small {
    max-width: 60px;
    max-height: 60px;
  }
}@media (min-width: 1024px) {
  .msnt-slide-item svg {
    max-width: 120px;
    max-height: 120px;
  }

  .msnt-slide-item svg.small {
    max-width: 80px;
    max-height: 80px;
  }
}@media (min-width: 1366px) {
  .msnt-slide-item svg {
    max-width: 150px;
    max-height: 150px;
  }

  .msnt-slide-item svg.small {
    max-width: 110px;
    max-height: 110px;
  }
}
/*# sourceMappingURL=item-svg.css.map */