.sidenav {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  color: #fff;
  width: 67px;
  overflow: hidden;
  z-index: 50;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 66px 0;
  display: none;
  background:
 -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(0, 0, 0, .65)),
    to(rgba(0, 0, 0, 1))
  );
  background:
 linear-gradient(
    to right,
    rgba(0, 0, 0, .65) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  border-radius: var(--msnt-border-radius);
  font-family: 'ClanProWideRegular';
  font-size: 14px;
}.sidenav.open {
  width: 300px;
  z-index: 101;
}.sidenav__wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(300px + 100%);
  padding: 3px 0;
}.sidenav__items {
  overflow-x: hidden;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 100%;
  width: 300px;
  padding-right: 20%;
}.sidenav.open .sidenav__items {
  width: 100%;
}.sidenav__item {
  cursor: pointer;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: nowrap;
      flex-flow: nowrap;
  height: 55px;
  min-height: 55px;
  width: 55px;
  min-width: 55px;
  margin: 3px 6px;
  border-radius: var(--msnt-border-radius);
  overflow: hidden;
}.sidenav.open .sidenav__item {
  width: 288px;
  min-width: 288px;
}.sidenav__item:hover,
.sidenav__item--active {
  background: #117011;
}.sidenav__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  width: 55px;
  min-width: 55px;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 6px;
}.sidenav:not(.open) .sidenav__item--active {
  background: #117011;
}.sidenav__preview-content {
  display: inline-block;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}.sidenav__detail {
  width: calc(100% - 55px);
  display: inline-block;
  height: 55px;
  float: right;
  line-height: 55px;
}.sidenav__control {
  position: absolute;
  left: 6px;
  right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 60px;
  width: 288px;
  z-index: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  font-size: 40px;
  -webkit-transition: color 0.15s linear;
  transition: color 0.15s linear;
  border-radius: var(--msnt-border-radius);
}.sidenav__control:hover {
  background: #117011;
}.sidenav__controls-top {
  top: 3px;
}.sidenav__controls-top.disabled {
  top: 0;
}.sidenav__controls-bottom {
  bottom: 3px;
}.sidenav__controls-bottom.disabled {
  bottom: 0;
}.sidenav__control.disabled {
  color: #bebebe;
  left: 0;
  right: 0;
  height: 66px;
}.sidenav.open .sidenav__control.disabled {
  background: #616161;
  width: 100%;
  height: 66px;
}@media (orientation: portrait) and (min-width: 768px), (orientation: landscape) and (min-width: 1024px) {
  .sidenav {
    display: block;
  }
}
/*# sourceMappingURL=sidenav.css.map */