.pageLeadership .contentWrapper {
    overflow: auto;
    padding-top: 100px;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}.pageLeadership h2 {
    width: 100%;
    font-size: 20px;
    margin: 45px 0 30px 0;
    font-weight: 400;
    font-family: var(--msnt-headings-font-family);
    text-align: center;
    text-transform: uppercase;
}.pageLeadership h4 {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: var(--msnt-headings-font-family);
    text-transform: uppercase;
    font-weight: 400;
}.pageLeadership .content ul {
    padding-left: 20px;
}.pageLeadership .content li {
    list-style: circle;
}.pageLeadership .content {
    max-width: 1440px;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
}.pageLeadership .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    /*grid-template-columns: repeat(auto-fill, 250px);*/
    width: 100%;
    gap: 30px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}.pageLeadership .grid .item {
    width: 300px;
}.pageLeadership .grid .item .bio {
    display: none;
}.pageLeadership .grid .image {
    display: none !important;
}.pageLeadership .grid .image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    position: relative;
    background: rgb(237, 237, 237);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(237, 237, 237, 1)), to(rgba(250, 250, 250, 1)));
    background: linear-gradient(180deg, rgba(237, 237, 237, 1) 0%, rgba(250, 250, 250, 1) 100%);
}.pageLeadership .grid .image img {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}.pageLeadership .grid .item.clickable {
    cursor: pointer;
}.pageLeadership .grid .item .preview {
    aspect-ratio: 1/1;
}.pageLeadership #profile-data_place .preview {
    display: none !important;
}.pageLeadership .grid .item .preview img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
    display: block;
    -o-object-position: bottom;
       object-position: bottom;
    background: rgb(237, 237, 237);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(237, 237, 237, 1)), to(rgba(250, 250, 250, 1)));
    background: linear-gradient(180deg, rgba(237, 237, 237, 1) 0%, rgba(250, 250, 250, 1) 100%);
}.pageLeadership .grid .item .text {
    word-spacing: 100vw;
    text-align: center;
}.pageLeadership .grid .item h3 {
    color: #50b0e0;
    font-size: 24px;
    margin-top: 15px;
    font-weight: 600;
    display: inline-block;
    word-spacing: normal;
    font-family: 'Nexa';
}.pageLeadership .grid .item p {
    font-size: 14px;
    display: inline-block;
    word-spacing: normal;
    margin-top: 5px;
    font-weight: 600;
    max-width: 80%;
    line-height: 1.125;
}.pageLeadership .grid .item p span {
    display: block;
}.pageLeadership .footer-block {
    padding: 70px 20px 40px 20px;
    margin-top: 60px;
    background: #f4f4f4;
    text-align: center;
}.pageLeadership .footer-block h2 {
    margin-bottom: 35px;
}.pageLeadership .footer-block a {
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 20px;
}.pageLeadership .footer-block a.mail {
    color: #50b0e0;
    margin: 20px 0;
}.pageLeadership .grid-profiles_wrapper {
    position: relative;
}.pageLeadership #profile-data_place,
.pageLeadership #directors-data_place {
    display: none;
    position: relative;
}.pageLeadership #profile-data_place {
    width: 100vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}.pageLeadership .profile-container {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageLeadership .profile-title hr {
    border-color: #c1c1c1;
    width: calc(100% - 10px);
    margin: 0 5px;
    height: 0;
    border-width: 0;
    border-bottom-width: 1px;
}.pageLeadership .profile-main_title {
    text-align: center;
    margin: 40px 0;
}.pageLeadership .profile-main_title h3 {
    font-size: 32px;
    font-family: var(--msnt-secondary-font-family);
}.pageLeadership .profile-main_title p {
    font-size: 20px;
}.pageLeadership .profile-main_image {
    height: 350px;
}.pageLeadership .profile-main_image img {
    height: 100%;
}.pageLeadership .profile-main_text {
    padding: 0 40px;
    max-width: 1000px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    line-height: 1.15;
    font-size: 20px;
}.pageLeadership .profile-main_text div {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 30px;
}.pageLeadership .profile-title h4 {
    font-size: 14px;
    line-height: 50px;
    font-weight: 400;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--msnt-headings-font-family);
}@media screen and (min-width: 768px) {
    .pageLeadership .profile-main_text {
        padding: 0 20px;
        line-height: 1.6;
        font-size: 22px;
    }

    .pageLeadership .profile-main {
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

    .pageLeadership .grid {
        grid-template-columns: repeat(auto-fill, 275px);
        gap: 75px 20px;
    }

    .pageLeadership .profile-main_title {
        width: 100%;
        text-align: start;
        margin: 60px 0;
    }

    .pageLeadership .profile-title h4 {
        font-size: 18px;
        line-height: 70px;
    }

    .pageLeadership h2 {
        font-size: 32px;
        margin: 50px 0;
    }

    .pageLeadership .profile-main_title h3 {
        font-size: 42px;
        max-width: 40%;
    }

    .pageLeadership .profile-main_title p {
        font-size: 22px;
        max-width: 40%;
    }

    .pageLeadership .footer-block {
        padding: 70px 40px 40px 40px;
        margin-top: 60px;
        background: #f4f4f4;
        margin-bottom: 65px;
        text-align: center;
    }
}@media screen and (max-width: 1440px) {
    .pageLeadership #profile-data_place {
        width: auto;
        margin: 0 -20px;
        left: 0;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}.pageLeadership .profile-main_image,
.pageLeadership .grid .item .preview {
    display: none;
}.pageLeadership .profile-main_title h3,
.pageLeadership .profile-main_title p {
    max-width: 80%;
    margin: 0 auto;
    min-width: 300px;
    text-align: center;
    line-height: 1.25;
}.pageLeadership .profile-main_title h3 {
    margin-bottom: 10px;
}@media screen and (min-width: 768px) {

    .pageLeadership .profile-main_title h3,
    .pageLeadership .profile-main_title p {
        max-width: 100%;
    }

    .pageLeadership .profile-main_title h3 {
        margin-bottom: 0;
    }

    .pageLeadership .profile-main_title p {
        margin-bottom: 10px;
    }
}.pageLeadership .grid .item h3 {
    margin-top: 0;
}
/*# sourceMappingURL=leadership.css.map */