#page_wrapper.pageBrandGuidelines {
    min-height: 100%;
    height: auto;
    background-color: #f9f9f9;
}.pageBrandGuidelines img {
    display: block;
    margin: 0 auto;
}.pageBrandGuidelines h2 {
    font-size: 52px;
    margin: 30px 0;
}.pageBrandGuidelines .content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageBrandGuidelines .alert {
    color: #ff0000;
    font-weight: 600;
}.pageBrandGuidelines .bordered {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    height: 75px;
    line-height: 1.1;
    border: 2px solid #ff0000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 10px;
}.pageBrandGuidelines .logotypes-block,
.pageBrandGuidelines .clear-space-block {
    background-color: #fff;
}.pageBrandGuidelines .header-block {
    height: 685px;
    background-color: #000;
    background-image: url('/design/src/img/brand-guidelines/header-bg2.jpg');
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #fff;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}.pageBrandGuidelines .header-block h1 {
    text-align: center;
    font-size: 48px;
}.pageBrandGuidelines p:not(.alert) {
    font-family: 'ClanProWideRegular';
    font-size: 15px;
}.pageBrandGuidelines .entry-block {
    padding: 30px 0 60px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}.pageBrandGuidelines .entry-block h1 {
    font-size: 52px;
    margin: 30px 0;
}.pageBrandGuidelines .entry-block p {
    line-height: 1.1;
    margin: 15px 0;
}.pageBrandGuidelines .logotypes-block {
    padding: 30px 0;
}.pageBrandGuidelines .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}.pageBrandGuidelines .grid-logotypes .item {
    padding: 0 30px;
}.pageBrandGuidelines .grid-logotypes .img {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 50px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 2px solid #000;
}.pageBrandGuidelines .grid-logotypes .img svg {
    width: 434px;
}.pageBrandGuidelines .grid-logotypes .img svg#svg-logo-mini {
    width: 117px;
}.pageBrandGuidelines .logotypes-block .content>.alert {
    margin-top: 75px;
    margin-bottom: 55px;
}.pageBrandGuidelines .logos-block {
    padding: 30px 0;
    text-align: center;
}.pageBrandGuidelines .grid-colors {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 150px;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    padding-top: 10px;
}.pageBrandGuidelines .grid-colors .item {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    max-width: 347px;
    padding: 0 10px;
}.pageBrandGuidelines .grid-colors .item svg {
    width: 100%;
    display: block;
}.pageBrandGuidelines .grid-colors .item .img {
    padding: 30px 35px;
}.pageBrandGuidelines .grid-colors .item-white .img {
    border: 2px solid #000;
    padding: 28px 33px;
}.pageBrandGuidelines .grid-colors .item-black .img {
    background-color: #000;
}.pageBrandGuidelines .grid-colors .item-red .img {
    background-color: #ff0000;
}.pageBrandGuidelines .grid-colors .title {
    text-align: left;
    font-size: 20px;
    margin-top: 20px;
}.pageBrandGuidelines .grid-colors .desc {
    text-align: left;
    line-height: 1.1;
}.pageBrandGuidelines .grid-codes {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
}.pageBrandGuidelines .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}.pageBrandGuidelines .grid-codes .item {
    min-width: 180px;
}.pageBrandGuidelines .grid-codes .img {
    border: 1px solid #000;
    padding: 20px;
    width: 152px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 20px;
}.pageBrandGuidelines .grid-codes .img>div {
    width: 110px;
    height: 110px;
}.pageBrandGuidelines .grid-codes .img .white {
    background-color: white;
    border: 1px solid black;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageBrandGuidelines .grid-codes .img .black {
    background-color: black;
}.pageBrandGuidelines .grid-codes .img .red {
    background-color: red;
}.pageBrandGuidelines .grid-codes .img>p {
    line-height: 1.1;
    font-weight: 600;
    font-size: 17px;
    margin: 20px -20px 0 -20px;
}.pageBrandGuidelines .grid-codes .img>p span {
    font-size: 13px;
}.pageBrandGuidelines .grid-codes .item>p {
    line-height: 2;
    font-weight: 600;
}.pageBrandGuidelines .grid-codes .item>p span {
    line-height: 2;
    font-weight: 400;
}.pageBrandGuidelines .logos-block .alert {
    margin-top: 70px;
    margin-bottom: 45px;
}.pageBrandGuidelines .clear-space-block {
    padding: 30px 0;
    text-align: center;
}.pageBrandGuidelines .clear-space-block .content>p:not(.alert) {
    max-width: 475px;
    margin: 0 auto;
    line-height: 1.1;
    margin-bottom: 75px;
    margin-top: 50px;
}.pageBrandGuidelines .grid-clear-space {
    margin-bottom: 45px;
}.pageBrandGuidelines .grid-clear-space .item:nth-child(1) {
    width: 100%;
    max-width: 534px;
}.pageBrandGuidelines .grid-clear-space .item:nth-child(2) {
    width: 100%;
    max-width: 206px;
}.pageBrandGuidelines .grid-clear-space .item+.item {
    margin-left: 55px;
}.pageBrandGuidelines .clear-space-block .alert {
    margin-bottom: 45px;
}.pageBrandGuidelines .download-block {
    background-color: #006fc1;
    background-image: url(/design/src/img/brand-guidelines/download-bg.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 35px 0 75px 0;
    text-align: center;
    position: relative;
}.pageBrandGuidelines .download-block:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #006fc1;
    opacity: 0.8;
}.pageBrandGuidelines .download-block .content {
    position: relative;
    z-index: 2;
}.pageBrandGuidelines .download-block h2 {
    color: #fff;
    margin-bottom: 5px;
}.pageBrandGuidelines .download-block a {
    display: inline-block;
    margin-top: 30px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    font-family: 'ClanProWideRegular';
}.pageBrandGuidelines .download-block a:nth-child(4) {
    margin-top: 55px;
}.pageBrandGuidelines .download-block svg {
    display: inline-block;
    width: 13px;
    height: 9px;
    fill: #fff;
    border: 2px solid #fff;
    padding: 1px 2px;
    border-top: 0;
}.pageBrandGuidelines #footer {
    margin-top: 0;
    border-top: 0;
}.pageBrandGuidelines .grid-table {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    border: 2px solid #000;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageBrandGuidelines .grid-table .img {
    border: 1px solid #000;
    position: relative;
    min-width: 0;
}.pageBrandGuidelines .grid-table .img svg {
    margin-top: -2.6%;
    min-width: 100%;
    min-height: 100%;
}.pageBrandGuidelines .grid-table div {
    outline: 1px solid #e1e1e1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}.pageBrandGuidelines .grid-table-mini {
    grid-template-columns: 4fr 7fr 4fr;
    grid-template-rows: 1fr 2fr 1fr;
    height: 215px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.pageBrandGuidelines .grid-table-mini .img svg {
    margin-top: 0;
    height: 100%;
}@media screen and (max-width: 1024px) {
    .pageBrandGuidelines .grid-clear-space {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .pageBrandGuidelines .grid-clear-space .item+.item {
        margin-left: 0;
        margin-top: 50px;
    }
}@media screen and (max-width: 768px) {
    .pageBrandGuidelines .header-block {
        height: 430px;
    }

    .pageBrandGuidelines .header-block h1 {
        font-size: 36px;
    }

    .pageBrandGuidelines h2 {
        font-size: 46px;
    }

    .pageBrandGuidelines .item {
        margin-top: 50px;
    }

    .pageBrandGuidelines .grid-logotypes .item:nth-child(1),
    .pageBrandGuidelines .grid-logotypes .item:nth-child(1) .img svg {
        width: 100%;
    }

    .pageBrandGuidelines .grid-logotypes .item:nth-child(1) .img {
        width: 100%;
        max-width: 538px;
    }
}@media screen and (max-width: 500px) {
    .pageBrandGuidelines .bordered {
        font-size: 10px;
        height: 55px;
        margin-top: 30px;
    }

    .pageBrandGuidelines h2 {
        margin: 25px 0 20px 0;
        font-size: 24px;
    }

    .pageBrandGuidelines .header-block {
        background-image: url('/design/src/img/brand-guidelines/header-bg-mini2.jpg');
    }

    .pageBrandGuidelines .header-block h1 {
        font-size: 50px;
        line-height: 1.25;
    }

    .pageBrandGuidelines .entry-block {
        padding: 0 0 20px 0;
    }

    .pageBrandGuidelines img {
        max-width: 100%;
    }

    .pageBrandGuidelines .entry-block p {
        font-size: 9px;
    }

    .pageBrandGuidelines .logotypes-block {
        padding: 0;
    }

    .pageBrandGuidelines .logotypes-block .content>.alert {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .pageBrandGuidelines .grid-logotypes .item {
        padding: 0px;
        margin: 0;
    }

    .pageBrandGuidelines .grid-logotypes p {
        font-size: 10px;
    }

    .pageBrandGuidelines .grid.grid-logotypes .item .img {
        margin-top: 20px;
        max-width: 265px;
        padding: 20px;
    }

    .pageBrandGuidelines .grid-logotypes .item+.item {
        margin-top: 30px;
    }

    .pageBrandGuidelines .grid-logotypes .item:nth-child(2) .img {
        max-width: 110px;
    }

    .pageBrandGuidelines .logos-block {
        padding: 20px 0 0 0;
    }

    .pageBrandGuidelines .grid-colors {
        padding: 0;
        margin-bottom: 60px;
    }

    .pageBrandGuidelines .grid-colors .item {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-top: 0;
        max-width: 250px;
    }

    .pageBrandGuidelines .grid-colors .item.item-white {
        margin-top: 0;
    }

    .pageBrandGuidelines .grid-colors .item .img {
        max-width: 175px;
        padding: 11px 16px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

    .pageBrandGuidelines .grid-colors .item.item-white .img {
        padding: 10px 15px;
    }

    .pageBrandGuidelines .grid-colors .title {
        font-size: 10px;
        text-align: center;
    }

    .pageBrandGuidelines .grid-colors .desc {
        font-size: 9px;
        text-align: center;
    }

    .pageBrandGuidelines .grid-codes {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    }

    .pageBrandGuidelines .grid-codes .item {
        min-width: auto;
        margin: 0;
    }

    .pageBrandGuidelines .grid-codes .item .img {
        padding: 10px;
        width: 77px;
        margin-bottom: 10px;
    }

    .pageBrandGuidelines .logos-block .alert.bordered {
        margin-bottom: 25px;
    }

    .pageBrandGuidelines .clear-space-block {
        padding: 10px 0;
    }

    .pageBrandGuidelines .clear-space-block h2 {
        margin: 15px 0;
    }

    .pageBrandGuidelines .clear-space-block .content>p:not(.alert) {
        font-size: 9px;
        margin-top: 20px;
        max-width: 270px;
        line-height: 1.25;
        margin-bottom: 30px;
    }

    .pageBrandGuidelines .grid-clear-space .item {
        margin: 0;
    }

    .pageBrandGuidelines .grid-clear-space .item+.item {
        margin-top: 25px;
    }

    .pageBrandGuidelines .grid-clear-space {
        margin-bottom: 30px;
    }

    .pageBrandGuidelines .grid-clear-space .item:nth-child(1) {
        max-width: 270px;
    }

    .pageBrandGuidelines .grid-clear-space .item:nth-child(2) {
        width: 105px;
        height: 105px;
    }

    .pageBrandGuidelines .clear-space-block .alert {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 45px;
    }

    .pageBrandGuidelines .grid-codes .img>div {
        width: 55px;
        height: 55px;
    }

    .pageBrandGuidelines .grid-codes .img>p {
        font-size: 9px;
        margin: 10px -10px 0;
    }

    .pageBrandGuidelines .grid-codes .img>p span {
        font-size: 7px;
    }

    .pageBrandGuidelines .grid-codes .item>p {
        font-size: 8px;
    }

    .pageBrandGuidelines .grid-table div {
        line-height: 1.1;
        font-size: 10px;
        font-weight: 600;
    }

    .pageBrandGuidelines .grid-table-mini {
        height: auto;
    }

    .pageBrandGuidelines .download-block {
        height: 180px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 30px 0 40px 0;
    }

    .pageBrandGuidelines .download-block h2 {
        margin: 0;
    }

    .pageBrandGuidelines .download-block .content a {
        font-size: 9px;
        margin-top: 21px;
    }

    .pageBrandGuidelines .download-block svg {
        display: inline-block;
        width: 8px;
        height: 5px;
        fill: #fff;
        border: 1px solid #fff;
        padding: 1px 2px;
        border-top: 0;
    }
}@media screen and (min-width: 1920px) {
    .pageBrandGuidelines .header-block {
        background-size: contain;
    }
}
/*# sourceMappingURL=brand-guidelines.css.map */