@import "v2/menu.css";@import "v2/fade-in-text.css";@font-face {
  font-family: Montserrat;
  font-weight: bold;
  src: url("/design/fonts/montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
}@font-face {
  font-family: Montserrat;
  src: url("/design/fonts/montserrat/Montserrat-Light.ttf") format("truetype");
}@font-face {
  font-family: ClanPro;
  src: url("/design/fonts/clan_pro/ClanPro.woff") format("woff"),
    url("/design/fonts/clan_pro/ClanPro.ttf") format("truetype");
}@font-face {
  font-family: ClanProWide;
  src: url("/design/fonts/clan_pro/ClanProWide.otf") format("truetype");
}@font-face {
  font-family: ClanProWideRegular;
  src: url("/design/fonts/clan_pro/ClanProWideRegular.otf") format("truetype");
}html,
body {
  height: 100%;
  width: 100%;
}body {
  font-family: var(--msnt-font-family);
  line-height: 1.5;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-text-size-adjust: 100%;
  min-width: 350px;
}.GoogleMap {
  width: 100%;
  height: 400px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
  filter: grayscale(100%);
}.contacts-wrapper {
  font-size: 0;
  line-height: 0;
}.oneContactWrapper {
  display: inline-block;
  width: 50%;
  margin: 0;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 20px;
  line-height: 1.5;
  font-size: 100%;
}.oneContactWrapper .map {
  width: 100%;
  height: 400px;
  background-position: center center;
  background-size: 200%;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  display: block;
  cursor: pointer;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.5" /><feFuncG type="linear" slope="0.5" /><feFuncB type="linear" slope="0.5" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}.oneContactWrapper .map:hover {
  background-size: 260%;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.75" /><feFuncG type="linear" slope="0.75" /><feFuncB type="linear" slope="0.75" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(0.75);
          filter: brightness(0.75);
}.countryflag {
  width: 56px;
  height: 42px;
  margin: 0 auto;
  background-position: center center;
  background-size: 100% 100%;
  border: 1px solid rgba(0, 0, 0, .4);
}.contentWrapperContacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}.flagworld {
  background-image: url(../../src/img/flags/flag1.jpg);
  background-size: 56px 42px;
  background-position: 50% 65%;
}.flaguk {
  background-image: url(../../src/img/flags/gb_v2.svg);
}.flagfrance {
  background-image: url(../../src/img/flags/fr.svg);
}.flagrussia {
  background-image: url(../../src/img/flags/ru.svg);
}.flaglatinamerica {
  background-image: url(../../src/img/flags/mx.svg);
}.flagitaly {
  background-image: url(../../src/img/flags/it.svg);
}.flagbrazil {
  background-image: url(../../src/img/flags/br.svg);
}.flagindia {
  background-image: url(../../src/img/flags/in.svg);
}.flagaustralia {
  background-image: url(../../src/img/flags/au.svg);
}.flagmiddleeast {
  background-image: url(../../src/img/flags/ae.svg);
}.flagchina {
  background-image: url(../../src/img/flags/cn.svg);
}.flaggermany {
  background-image: url(../../src/img/flags/de.svg);
}.flagnetherlands {
  background-image: url(../../src/img/flags/nl.svg);
}.flagusa {
  background-image: url(../../src/img/flags/us.svg);
}.flagcanada {
  background-image: url(../../src/img/flags/ca.svg);
}.flagspain {
  background-image: url(../../src/img/flags/es.svg);
}.flagjapan {
  background-image: url(../../src/img/flags/jp.svg);
}.flagturkey {
  background-image: url(../../src/img/flags/tr.svg);
}.flaghungary {
  background-image: url(../../src/img/flags/hu.svg);
}.flagukraine {
  background-image: url(../../src/img/flags/ua.svg);
}.flagindonesia {
  background-image: url(../../src/img/flags/id.svg);
}.flagswiss {
  background-image: url(../../src/img/flags/ch.svg);
}img[src="../img/our-brands/motorstore_v4.png"] {
  -webkit-transform: scale(1.33);
          transform: scale(1.33);
}.clear {
  clear: both;
}.hide {
  display: none !important;
}@media screen and (max-width: 767px) {
  .m-hide {
    display: none !important;
  }

  .m-wrap {
    display: block;
  }
}.emails .item a {
  text-align: center;
}.video_overlay,
.about_us_video {
  max-width: none;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translateX(-50%) translateY(-50%);
}.video_overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}.video,
section > .video-player {
  padding-bottom: 56.25%;
  position: relative;
}section > .video-player iframe,
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}@media (min-aspect-ratio: 16/9) {
  .about_us_video {
    max-height: none;
    max-width: 100%;
  }
}.scrollToTop {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 45px;
  cursor: pointer;
  color: #000;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 50%;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 100;
}.menuVisible .scrollToTop {
  opacity: 0;
}.atTop .scrollToTop {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}.scrollToTop:hover {
  -webkit-box-shadow: inset 0 0 0 20px #000;
          box-shadow: inset 0 0 0 20px #000;
  color: #fff;
}/* Overlay */#overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: rgba(255, 255, 255, .4);
}#overlay .overlayWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 800px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}#overlay .closeBtn {
  position: absolute;
  right: -40px;
  top: -30px;
  display: block;
  cursor: pointer;
  font-size: 30px;
}#overlay .overlayContent {
  background: #fff;
  padding: 40px;
  color: #949494;
}#overlay .overlayContent .wrapper {
  overflow: auto;
  max-height: 600px;
}#overlay .overlayContent .top {
  margin-bottom: 20px;
}#overlay .overlayContent .top span {
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 10px;
}#overlay .overlayContent img {
  width: 215px;
  margin-right: 20px;
  margin-bottom: 20px;
  float: left;
}#overlay .overlayContent .title {
  font-weight: bold;
  font-size: 20px;
  color: #000;
}#overlay .overlayContent .mainText {
  clear: both;
  font-family: ClanPro;
}#overlay .overlayContent .mainText p {
  margin: 10px 0;
  font-size: 14px;
}/* END Overlay */.fullpageNav {
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}.slidesWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s linear;
  transition: -webkit-transform 0.5s linear;
  transition: transform 0.5s linear;
  transition: transform 0.5s linear, -webkit-transform 0.5s linear;
}.slidesWrapper.preventAnimation123123 {
  -webkit-transition-duration: 0.001s;
  transition-duration: 0.001s;
}.slidesWrapper.preventAnimation {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}.slidesWrapper .slide {
  width: 100%;
  height: 100%;
  /*min-width: 768px;*/
  position: relative;
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}.slidesWrapper.hidden .slide {
  opacity: 0;
}.slidesWrapper .slideBackgrounds,
.slidesWrapper .imagesWrapper,
.slidesWrapper .imagesWrapper .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}.slidesWrapper .imagesWrapper {
  overflow: hidden;
}.slidesWrapper .slideBackgrounds .bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-transition: opacity 1s linear,
    -webkit-transform 10s cubic-bezier(0.27, 0.4, 0.34, 0.6) 1s;
  transition: opacity 1s linear,
    -webkit-transform 10s cubic-bezier(0.27, 0.4, 0.34, 0.6) 1s;
  transition: opacity 1s linear,
    transform 10s cubic-bezier(0.27, 0.4, 0.34, 0.6) 1s;
  transition: opacity 1s linear,
    transform 10s cubic-bezier(0.27, 0.4, 0.34, 0.6) 1s,
    -webkit-transform 10s cubic-bezier(0.27, 0.4, 0.34, 0.6) 1s;
  opacity: 0;
}.slidesWrapper .current .bg.current {
  opacity: 1;
  /*background-size: 150%;*/
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}/* Corporate */.slideCorporate.current .slideBackgrounds .bg.current {
  -webkit-transform: none;
  transform: none;
}/* Automotor */.slideAutoMotor.current .slideBackgrounds .bg0.current {
  -webkit-transform: translate(0, 5%) scale(1.1);
  transform: translate(0, 5%) scale(1.1);
}.slideAutoMotor.current .slideBackgrounds .bg2 {
  -webkit-transform: translate(0, -5%) scale(1.1);
  transform: translate(0, -5%) scale(1.1);
}.slideAutoMotor.current .slideBackgrounds .bg2.current {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}.slideAutoMotor.current .slideBackgrounds .bg3 {
  -webkit-transform: translate(-2%, 5%) scale(1.1);
  transform: translate(-2%, 5%) scale(1.1);
}.slideAutoMotor.current .slideBackgrounds .bg3.current {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}/* Motorsport.com */.slideMotorsport.current .slideBackgrounds .bg0.current {
  -webkit-transform: translate(5%, 5%) scale(1.1);
  transform: translate(5%, 5%) scale(1.1);
}.slideMotorsport.current .slideBackgrounds .bg1.current {
  -webkit-transform: translate(-5%, 0) scale(1.1);
  transform: translate(-5%, 0) scale(1.1);
}.slideMotorsport.current .slideBackgrounds .bg3 {
  -webkit-transform: translate(-5%, 0) scale(1.1);
  transform: translate(-5%, 0) scale(1.1);
}.slideMotorsport.current .slideBackgrounds .bg3.current {
  -webkit-transform: translate(5%, 0) scale(1.1);
  transform: translate(5%, 0) scale(1.1);
}/* Motorsport TV */.slideMotorsportTv.current .slideBackgrounds .bg0.current {
  -webkit-transform: translate(0, 5%) scale(1.1);
  transform: translate(0, 5%) scale(1.1);
}.slideMotorsportTv .slideBackgrounds .bg1 {
  -webkit-transform: translate(-5%, 0) scale(1.1);
  transform: translate(-5%, 0) scale(1.1);
}.slideMotorsportTv.current .slideBackgrounds .bg1.current {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}.slideMotorsportTv .slideBackgrounds .bg2 {
  -webkit-transform: translate(5%, 5%) scale(1.1);
  transform: translate(5%, 5%) scale(1.1);
}.slideMotorsportTv.current .slideBackgrounds .bg2.current {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}/* Motor1.com */.slideMotor1 .slideBackgrounds .bg0 {
  -webkit-transform: translate(0, 5%) scale(1.1);
  transform: translate(0, 5%) scale(1.1);
}.slideMotor1.current .slideBackgrounds .bg0.current {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}.slideMotor1.current .slideBackgrounds .bg1.current {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}.slideMotor1 .slideBackgrounds .bg2 {
  -webkit-transform: translate(0, 5%) scale(1.1);
  transform: translate(0, 5%) scale(1.1);
}.slideMotor1.current .slideBackgrounds .bg2.current {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}/* Motorstore.com */.slideMotorstore.current .slideBackgrounds .bg1.current {
  -webkit-transform: translate(-5%, -5%) scale(1.1);
  transform: translate(-5%, -5%) scale(1.1);
}.slideMotorstore .slideBackgrounds .bg2.current {
  -webkit-transform: translate(-5%, 5%) scale(1.1);
  transform: translate(-5%, 5%) scale(1.1);
}/* Motorsportstats.com */.slideMotorsportstats .slideBackgrounds .bg1 {
  background-position: bottom right;
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}.slideMotorsportstats.current .slideBackgrounds .bg1.current {
  -webkit-transform: translate(-5%, -5%) scale(1.1);
  transform: translate(-5%, -5%) scale(1.1);
}.slideMotorsportstats .slideBackgrounds .bg2.current {
  -webkit-transform: translate(-5%, 0) scale(1.1);
  transform: translate(-5%, 0) scale(1.1);
}.iOS .slideBackgrounds .bg {
  -webkit-transform: none !important;
  transform: none !important;
}.slidesWrapper .slideControls {
}.slidesWrapper .slideControls .slideControl {
  display: block;
  cursor: pointer;
  width: 44px;
  height: 96px;
  position: absolute;
  top: 50%;
  margin-top: -48px;
  background: url(../../src/img/arrows.png) no-repeat top left;
}.slidesWrapper .slideControls .prevSlideBtn {
  left: 10px;
  background-position: top left;
}.slidesWrapper .slideControls .nextSlideBtn {
  right: 10px;
  background-position: 0 -96px;
}.slidesWrapper .imagesNavigation {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}.slidesWrapper .imagesNavigation span {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}.slidesWrapper .imagesNavigation span span {
  display: inline-block;
  cursor: pointer;
  width: 6px;
  height: 6px;
  position: relative;
  top: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: box-shadow 0.3s linear, height 0.3s linear,
    width 0.3s linear;
  -webkit-transition: height 0.3s linear, width 0.3s linear, -webkit-box-shadow 0.3s linear;
  transition: height 0.3s linear, width 0.3s linear, -webkit-box-shadow 0.3s linear;
  transition: box-shadow 0.3s linear, height 0.3s linear, width 0.3s linear;
  transition: box-shadow 0.3s linear, height 0.3s linear, width 0.3s linear, -webkit-box-shadow 0.3s linear;
}/*
        .slidesWrapper .imagesNavigation span + span{
            margin-left: 25px;
        }
        */.slidesWrapper .imagesNavigation span.current span {
  width: 12px;
  height: 12px;
}.slidesWrapper .current .imagesNavigation span.current span {
  -webkit-box-shadow: inset 0 0 0 6px red;
          box-shadow: inset 0 0 0 6px red;
  -webkit-transition: box-shadow 9.5s linear, height 0.3s linear,
    width 0.3s linear;
  -webkit-transition: height 0.3s linear, width 0.3s linear, -webkit-box-shadow 9.5s linear;
  transition: height 0.3s linear, width 0.3s linear, -webkit-box-shadow 9.5s linear;
  transition: box-shadow 9.5s linear, height 0.3s linear, width 0.3s linear;
  transition: box-shadow 9.5s linear, height 0.3s linear, width 0.3s linear, -webkit-box-shadow 9.5s linear;
}.slidesWrapper .imagesNavigation span.current span {
  background: #fff;
}.slidesWrapper .slideContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 50px;
}.slideContent .badge {
  display: inline-block;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 19px;
  text-transform: uppercase;
  margin-top: 20px;
}.slideContent .badge a {
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: right center;
  line-height: 29px;
  -webkit-transition: color 0.4s linear;
  transition: color 0.4s linear;
}.slideContent .badge .commingSoon {
  -webkit-transition: none;
  transition: none;
}.slideContent .badge a span {
  color: #000;
  padding: 5px;
  border-radius: 50%;
  margin-left: 10px;
  -webkit-transition: box-shadow 0.4s linear, color 0.4s linear;
  -webkit-transition: color 0.4s linear, -webkit-box-shadow 0.4s linear;
  transition: color 0.4s linear, -webkit-box-shadow 0.4s linear;
  transition: box-shadow 0.4s linear, color 0.4s linear;
  transition: box-shadow 0.4s linear, color 0.4s linear, -webkit-box-shadow 0.4s linear;
}.slideContent .badge a:hover span {
  -webkit-box-shadow: inset 0 0 0 15px #fff;
          box-shadow: inset 0 0 0 15px #fff;
}.slidesWrapper .slideMotorsport .badge a {
  color: #ff0000;
}.slidesWrapper .slideMotorsport .badge span {
  background: #ff0000;
  color: #fff;
}.slidesWrapper .slideMotorsport .badge a:hover {
  color: #fff;
}.slidesWrapper .slideMotorsport .badge a:hover span {
  color: #ff0000;
  -webkit-box-shadow: inset 0 0 0 20px #fff;
          box-shadow: inset 0 0 0 20px #fff;
}.slidesWrapper .slideMotorsportTv .badge a {
  color: #ffdd00;
}.slidesWrapper .slideMotorsportTv .badge span {
  background: #ffdd00;
}.slidesWrapper .slideMotorsportTv .badge a:hover span {
  color: #ffdd00;
}.slidesWrapper .slideMotor1 .badge a {
  color: #007eff;
}.slidesWrapper .slideMotor1 .badge span {
  background-color: #007eff;
  color: #fff;
}.slidesWrapper .slideMotor1 .badge a:hover span {
  color: #007eff;
}.slidesWrapper .slideFormulaE .badge a {
  color: #00aaff;
}.slidesWrapper .slideFormulaE .badge span {
  background-color: #00aaff;
  color: #fff;
}.slidesWrapper .slideFormulaE .badge a:hover span {
  color: #00aaff;
}.slidesWrapper .slideMotorstore .badge a {
  color: #fc0b2a;
}.slidesWrapper .slideMotorstore .badge span {
  background-color: #fc0b2a;
  color: #fff;
}.slidesWrapper .slideMotorstore .badge a:hover span {
  color: #fc0b2a;
}.slidesWrapper .slideMotorsportstats .badge a {
  color: #f4c403;
}.slidesWrapper .slideMotorsportstats .badge span {
  background-color: #f4c403;
  color: #000;
}.slidesWrapper .slideMotorsportstats .badge a:hover span {
  color: #f4c403;
}.slidesWrapper .slideAmalgam .badge a {
  color: #ff020e;
}.slidesWrapper .slideAmalgam .badge span {
  background-color: #ff020e;
  color: #fff;
}.slidesWrapper .slideAmalgam .badge a:hover span {
  color: #ff020e;
}.slidesWrapper .slideGiorgioPiola .badge a {
  color: #f6ff00;
}.slidesWrapper .slideGiorgioPiola .badge span {
  background-color: #f6ff00;
  color: #000;
}.slidesWrapper .slideGiorgioPiola .badge a:hover span {
  color: #f6ff00;
}.slidesWrapper .slideAutosport .badge a,
.slidesWrapper .slideAutosportMagazine .badge a,
.slidesWrapper .slideF1Racing .badge a,
.slidesWrapper .slideFerrariChat .badge a {
  color: #ff0000;
}.slidesWrapper .slideAutosport .badge span,
.slidesWrapper .slideAutosportMagazine .badge span,
.slidesWrapper .slideF1Racing .badge span,
.slidesWrapper .slideFerrariChat .badge span {
  background-color: #ff0000;
  color: #fff;
}.slidesWrapper .slideAutosport .badge a:hover span,
.slidesWrapper .slideAutosportMagazine .badge a:hover span,
.slidesWrapper .slideF1Racing .badge a:hover span,
.slidesWrapper .slideFerrariChat .badge a:hover span {
  color: #ff0000;
}.slidesWrapper .slideAutosportInternational .badge a {
  color: #00b1ff;
}.slidesWrapper .slideAutosportInternational .badge span {
  background-color: #00b1ff;
  color: #fff;
}.slidesWrapper .slideAutosportInternational .badge a:hover span {
  color: #00b1ff;
}.slidesWrapper .slideLatImages .badge a {
  color: #00cbfe;
}.slidesWrapper .slideLatImages .badge span {
  background-color: #00cbfe;
  color: #fff;
}.slidesWrapper .slideLatImages .badge a:hover span {
  color: #00cbfe;
}.slidesWrapper .slideAutosportAwards .badge a {
  color: #f9f100;
}.slidesWrapper .slideAutosportAwards .badge span {
  background-color: #f9f100;
  color: #000;
}.slidesWrapper .slideAutosportAwards .badge a:hover span {
  color: #f9f100;
}.slidesWrapper .slideGaming .badge a {
  color: #6000ff;
}.slidesWrapper .slideGaming .badge span {
  background-color: #6000ff;
  color: #fff;
}.slidesWrapper .slideGaming .badge a:hover span {
  color: #6000ff;
}.slidesWrapper .slideMotorstore .bg1 {
  background-position: right bottom;
}.slidesWrapper .slideMotorstore .bg1 {
  background-position: right top;
}.slidesWrapper .slideRideApart .badge a {
  color: #ffc000;
}.slidesWrapper .slideRideApart .badge span {
  background-color: #ffc000;
  color: #000;
}.slidesWrapper .slideRideApart .badge a:hover span {
  color: #ffc000;
}.slidesWrapper .slideInsideEvs .badge a {
  color: #00aaff;
}.slidesWrapper .slideInsideEvs .badge span {
  background-color: #00aaff;
  color: #fff;
}.slidesWrapper .slideInsideEvs .badge a:hover span {
  color: #00aaff;
}.slidesWrapper .forix .badge a {
  color: #ffd300;
}.slidesWrapper .forix .badge span {
  background-color: #ffd300;
  color: #000;
}.slidesWrapper .forix .badge a:hover span {
  color: #ffd300;
}.slidesWrapper .motorgt .badge a {
  color: #f00;
}.slidesWrapper .motorgt .badge span {
  background-color: #f00;
  color: #fff;
}.slidesWrapper .motorgt .badge a:hover span {
  color: #f00;
}.slidesWrapper .gppredictor .badge a {
  color: #ff9400;
}.slidesWrapper .gppredictor .badge span {
  background-color: #ff9400;
  color: #fff;
}.slidesWrapper .gppredictor .badge a:hover span {
  color: #ff9400;
}.slidesWrapper .motorsportjobs .badge a {
  color: #47c700;
}.slidesWrapper .motorsportjobs .badge span {
  background-color: #47c700;
  color: #000;
}.slidesWrapper .motorsportjobs .badge a:hover span {
  color: #47c700;
}.slidesWrapper .autoclassic .badge a {
  color: #bf0013;
}.slidesWrapper .autoclassic .badge span {
  background-color: #bf0013;
  color: #000;
}.slidesWrapper .autoclassic .badge a:hover span {
  color: #bf0013;
}.slidesWrapper .motormarket .badge a {
  color: #008ad8;
}.slidesWrapper .motormarket .badge span {
  background-color: #008ad8;
  color: #000;
}.slidesWrapper .motormarket .badge a:hover span {
  color: #008ad8;
}.slidesWrapper .dukevideo .badge a {
  color: #ff0000;
}.slidesWrapper .dukevideo .badge span {
  background-color: #ff0000;
  color: #fff;
}.slidesWrapper .dukevideo .badge a:hover span {
  color: #ff0000;
}.slidesWrapper .slideMotorsportExperiences .badge a {
  color: #ffba00;
}.slidesWrapper .slideMotorsportExperiences .badge span {
  background-color: #ffba00;
  color: #fff;
}.slidesWrapper .slideMotorsportExperiences .badge a:hover span {
  color: #ffba00;
}.slidesWrapper .slideMotorsportExperiences .titleWrapper h2 {
  font-size: 0.8em;
  white-space: nowrap;
}.slidesWrapper .slideMotorsportGames .titleWrapper h2 {
  font-size: 0.8em;
  white-space: nowrap;
}.slidesWrapper .slideLeMansEsports .badge a {
  color: #7200ff;
}.slidesWrapper .slideLeMansEsports .badge span {
  background-color: #7200ff;
  color: #fff;
}.slidesWrapper .slideLeMansEsports .badge a:hover span {
  color: #7200ff;
}.slidesWrapper .slideLeMansEsports .titleWrapper span {
  display: block;
  letter-spacing: 2px;
}.slidesWrapper .slideMotorsportPro .badge a {
  color: #ff7000;
}.slidesWrapper .slideMotorsportPro .badge span {
  background-color: #ff7000;
  color: #fff;
}.slidesWrapper .slideMotorsportPro .badge a:hover span {
  color: #ff7000;
}.slidesWrapper .slideMotorsportPro .titleWrapper span {
  display: block;
  letter-spacing: 2px;
}.slidesWrapper .slidePitStopBetting .badge a {
  color: #00ff0a;
}.slidesWrapper .slidePitStopBetting .badge span {
  background-color: #00ff0a;
  color: #fff;
}.slidesWrapper .slidePitStopBetting .badge a:hover span {
  color: #00ff0a;
}.slidesWrapper .slidePitStopBetting .titleWrapper span {
  display: block;
  letter-spacing: 2px;
}/* MotorsportGallery */.slidesWrapper .slideMotorsportGallery .badge a {
  color: #ff0a00;
}.slidesWrapper .slideMotorsportGallery .badge span {
  background-color: #ff0a00;
  color: #fff;
}.slidesWrapper .slideMotorsportGallery .badge a:hover span {
  color: #ff0a00;
}.slidesWrapper .slideMotorsportGallery .titleWrapper span {
  display: block;
  letter-spacing: 2px;
}/*END  MotorsportGallery *//* MotorsportImages */.slidesWrapper .slideMotorsportImages .badge a {
  color: #00cbff;
}.slidesWrapper .slideMotorsportImages .badge span {
  background-color: #00cbff;
  color: #fff;
}.slidesWrapper .slideMotorsportImages .badge a:hover span {
  color: #00cbff;
}/*END  MotorsportImages *//* MotorsportGames */.slidesWrapper .slideMotorsportGames .badge a {
  color: #6200ff;
}.slidesWrapper .slideMotorsportGames .badge span {
  background-color: #6200ff;
  color: #fff;
}.slidesWrapper .slideMotorsportGames .badge a:hover span {
  color: #6200ff;
}/*END  MotorsportGames *//* MotorsportMYEV */.slidesWrapper .slideMotorsportMYEV .badge a {
  color: #3bfd00;
}.slidesWrapper .slideMotorsportMYEV .badge span {
  background-color: #3bfd00;
  color: #fff;
}.slidesWrapper .slideMotorsportFans .badge a:hover span {
  color: #3bfd00;
}/*END  MotorsportMYEV *//* MotorsportFans */.slidesWrapper .slideMotorsportFans .badge a {
  color: #ff0000;
}.slidesWrapper .slideMotorsportFans .badge span {
  background-color: #ff0000;
  color: #fff;
}.slidesWrapper .slideMotorsportFans .badge a:hover span {
  color: #ff0000;
}/*END  MotorsportFans *//* MotorsportLeaders */.slidesWrapper .slideMotorsportLeaders .badge a {
  color: #ff0000;
}.slidesWrapper .slideMotorsportLeaders .badge span {
  background-color: #ff0000;
  color: #fff;
}.slidesWrapper .slideMotorsportLeaders .badge a:hover span {
  color: #ff0000;
}.slidesWrapper .slideMotorsportLeaders h2 {
  line-height: 1;
}/*END  MotorsportLeaders */.slideContent .badge a:hover {
  color: #fff;
}.slideContent .badge .commingSoon:hover {
  color: #0090ff;
}.slideContent .slogan {
  font-size: 46px;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 0 0 30px #000;
}.slideContent .titleWrapper {
  font-size: 95px;
  margin: 40px 0 20px;
  text-transform: uppercase;
  text-shadow: 0 0 30px #000;
}.slideAutosportInternational .titleWrapper {
  font-size: 82px;
}.slideContent .titleWrapper a {
  text-decoration: none;
  color: #fff;
}.slideFerrariChat .slideContent .titleWrapper {
  text-shadow: none;
}.slideContent .bigLogo {
  max-width: 867px;
}.slideContent p {
  font-family: ClanPro;
  font-size: 23px;
  text-shadow: 0 0 10px #000;
  line-height: 1.3;
}/*
    .slidesWrapper .slideAutoMotor {
        background-image: url(../img/about-us/automotor.jpg);
    }
    .slidesWrapper .slideMotorsport {
        background-image: url(../img/about-us/motorsport.jpg);
    }
    .slidesWrapper .slideMotorsportTv {
        background-image: url(../img/about-us/motorsport_tv.jpg);
    }
    .slidesWrapper .slideMotor1 {
        background-image: url(../img/about-us/motor1.jpg);
    }
    /* */.nextSlide {
  z-index: 100;
  cursor: pointer;
  position: fixed;
  left: 50%;
  bottom: 5px;
  margin-left: -22px;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 49px;
  font-size: 25px;
  opacity: 1;
  border: var(--msnt-button-next-color-border);
  color: var(--msnt-button-next-color);
  padding: 5px;
  border-radius: 50%;
  background: var(--msnt-button-next-background);
  -webkit-transition: -webkit-transform 0.2s linear, box-shadow 0.1s linear,
    opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear, -webkit-box-shadow 0.1s linear;
  transition: opacity 0.2s linear, -webkit-transform 0.2s linear, -webkit-box-shadow 0.1s linear;
  transition: transform 0.2s linear, box-shadow 0.1s linear, opacity 0.2s linear;
  transition: transform 0.2s linear, box-shadow 0.1s linear, opacity 0.2s linear, -webkit-transform 0.2s linear, -webkit-box-shadow 0.1s linear;
}@media screen and (max-width: 767px), screen and (max-height: 500px) {
  .nextSlide {
    left: auto;
    margin-left: auto;
    right: 15px;
    bottom: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}.menuVisible .nextSlide {
  opacity: 0;
}[data-page="aboutUs"] .nextSlide {
  background: transparent;
  border: 2px solid red;
  color: #000;
}.nextSlide.hidden {
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}.nextSlide.animate {
  -webkit-transition: -webkit-transform 0.3s linear, box-shadow 0.2s linear,
    color 0.3s linear;
  -webkit-transition: color 0.3s linear, -webkit-transform 0.3s linear, -webkit-box-shadow 0.2s linear;
  transition: color 0.3s linear, -webkit-transform 0.3s linear, -webkit-box-shadow 0.2s linear;
  transition: transform 0.3s linear, box-shadow 0.2s linear, color 0.3s linear;
  transition: transform 0.3s linear, box-shadow 0.2s linear, color 0.3s linear, -webkit-transform 0.3s linear, -webkit-box-shadow 0.2s linear;

  -webkit-animation: jump 2s ease 0s infinite normal;
  animation: jump 2s ease 0s infinite normal;
}.nextSlide:hover {
  background: var(--msnt-button-next-background);
  -webkit-box-shadow: inset 0 0 0 30px var(--msnt-button-next-background-hover);
          box-shadow: inset 0 0 0 30px var(--msnt-button-next-background-hover);
  color: var(--msnt-button-next-color-hover);
}.reversveNav .nextSlide {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}.reversveNav .nextSlide.hidden {
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}.reversveNav .nextSlide.animate {
  -webkit-animation: jump-rotated 2s ease 0s infinite normal;
  animation: jump-rotated 2s ease 0s infinite normal;
}/* START infographics */.infographic {
  margin-bottom: 60px;
  text-align: center;
}.infographic .item {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: left bottom;
  text-align: left;
  vertical-align: top;
}.infographic .item + .item {
  margin-left: 45px;
}.infographic .item .imgWrapper {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}.infographic .item .imgWrapper.hidden {
  visibility: hidden;
}.infographic .item .imgWrapper svg {
  /*filter:url(#dropshadow);*/
}.infographic:not(.infographicVertical) .item .imgWrapper svg {
  position: relative;
}@media screen and (max-width: 420px) {
  .infographic:not(.infographicVertical) .item .imgWrapper svg {
    position: relative;
  }
}.infographic .item .imgWrapper line,
.infographic .item .imgWrapper path,
.infographic .item .imgWrapper circle,
.infographic .item .imgWrapper rect,
.infographic .item .imgWrapper polygon,
.infographic .item .imgWrapper polyline,
.infographic .item .imgWrapper ellipse {
  stroke: #fff;
  stroke-width: 2;
  fill: none;
  /*stroke-miterlimit: 10;*/
}.slideMotorsportExperiences .infographic .item .imgWrapper line,
.slideMotorsportExperiences .infographic .item .imgWrapper path,
.slideMotorsportExperiences .infographic .item .imgWrapper circle,
.slideMotorsportExperiences .infographic .item .imgWrapper rect,
.slideMotorsportExperiences .infographic .item .imgWrapper polygon,
.slideMotorsportExperiences .infographic .item .imgWrapper polyline,
.slideMotorsportExperiences .infographic .item .imgWrapper ellipse {
  stroke-width: 1;
  /*stroke-miterlimit: 10;*/
}.infographic .item .imgWrapper .stretcher {
  visibility: hidden;
}.infographic .descr {
  line-height: 1;
}.infographic .descr > div {
  display: inline-block;
  vertical-align: middle;
}.slideMotorsportTv .infographic .descr {
}.infographic .digit {
  font-size: 80px;
  display: inline-block;
  vertical-align: baseline;
}.infographic .digit span {
  display: inline-block;
  visibility: hidden;
  text-align: right;
}.slideMotorsportTv .infographic .digit {
  display: block;
}.infographic .text {
  font-family: ClanPro;
  vertical-align: baseline;
  display: inline-block;
  font-size: 18px;
  margin-left: 10px;
  line-height: 1.3;
}.infographicVertical .item {
  height: auto;
  width: 30%;
}.infographicVertical .item .imgWrapper {
  display: block;
  margin-right: 0;
  margin-bottom: 18px;
}.infographicVertical .descr {
  padding-left: 0;
  text-align: center;
}.infographic .text {
  margin-left: 0;
}.infographicVertical .item + .item {
  /* margin-left: 100px; */
  margin-left: 3%;
}/* END infographics */#page_wrapper {
  height: 100%;
}#page_wrapper.pageNews {
  min-height: 100vh;
}.headerBanner {
  height: 42vw;
  min-height: 65vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
  text-align: center;

  /* Internet Explorer 10 */
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;

  /* Firefox */
  display: -moz-box;
  -moz-box-pack: center;
  -moz-box-align: center;

  /* Safari, Opera, and Chrome */
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  /* W3C */
  display: box;
  box-pack: center;
  box-align: center;

  overflow: hidden;
}.headerBanner .content {
  position: relative;
  z-index: 1;
}.Motorsport .pageAboutUs .headerBanner {
  height: 75vw;
}@media screen and (min-width: 768px) {
  .Motorsport .pageAboutUs .headerBanner {
    height: 29vw;
    background-size: contain;
  }
}.headerBanner .wrapper {
  width: 100%;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 1000px;
  margin-top: 60px;
}.headerBanner h1 {
  /* position: absolute; */
  /* text-align: center; */
  /* width: 90%; */
  /* top: 50%; */
  font-size: 38px;
  /* margin-top: -19px; */
  text-transform: uppercase;
  /* margin: 0 5%; */
}.headerBanner h2 {
  text-align: left;
  font-size: 46px;
  line-height: 1.2;
}.headerBanner p {
  font-family: ClanPro;
  line-height: 1.3;
  font-size: 18px;
}.frame-border {
  position: absolute;
  background-color: transtparent;
  z-index: 102;
  -webkit-transition: background-color 1s linear;
  transition: background-color 1s linear;
}.frame-border.left,
.frame-border.right {
  width: 12px;
  height: calc(100% - 24px);
  top: 12px;
}.frame-border.left {
  left: 0;
}.frame-border.right {
  right: 0;
}.frame-border.bottom,
.frame-border.top {
  height: 12px;
  width: 100%;
  left: 0;
}.frame-border.top {
  top: 0;
}.frame-border.bottom {
  bottom: 0;
}[data-page="home"].fullpageNav {
  background-color: #000;
}@media screen and (min-width: 768px) {
  [data-page="home"].fullpageNav {
    overflow: hidden;
  }

  .headerBanner p {
    font-size: 21px;
  }

  .nextSlide {
    bottom: 11px;
  }

  .frame-border {
    display: block;
    background-color: #ffffff;
  }

  .frame-border.left,
  .frame-border.right {
    width: 25px;
    height: calc(100% - 50px);
    top: 25px;
  }

  .frame-border.bottom,
  .frame-border.top {
    height: 25px;
  }

  [data-page="aboutUs"] .corporateContent p {
    text-align: justify;
  }
}.contentWrapper {
  background-color: #fff;
  position: relative;
  margin-top: -1px;
}.pager {
  background: #f5f5f5;
  text-align: center;
  line-height: 80px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
}h2.floating,
.pager.floating {
  background: #f0f0f0;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
          box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  top: 76px;
  left: 10px;
  right: 10px;
  z-index: 101;
  line-height: 40px;
  width: calc(100% - 20px);
  position: fixed;
  padding-top: 5px;
  margin-top: -5px;
  border-radius: 0 0 var(--msnt-border-radius) var(--msnt-border-radius);
}.pager.floating:before {
  content: "";
  display: block;
  width: calc(100% - 20px);
  height: calc(2 * var(--msnt-border-radius));
  border-radius: 0 0 var(--msnt-border-radius) var(--msnt-border-radius);
  position: fixed;
  top: calc(76px - var(--msnt-border-radius) - var(--msnt-border-radius));
  left: 10px;
  right: 10px;
  background: #fff;
}.menuVisible .pager.floating {
  z-index: 20;
}@media screen and (max-width: 767px) {
  .pager {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }

  h2.floating,
  .pager.floating {
    top: 68px;
  }

  .pager.floating:before {
    top: calc(64px - var(--msnt-border-radius));
  }
}.pager a {
  color: red;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
  min-width: 100px;
  display: inline-block;
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}.pager a:hover,
.pager a.current {
  color: #000;
}.pager a + a {
  margin-left: 0;
}#footer {
  text-align: center;
  /*line-height: 100px;*/
  height: 60px;
  margin: 25px 40px 0;
  border-top: 1px solid #c5c5c5;
  font-size: 12px;
  line-height: 1;

  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;

  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;

  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;

  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
}.pageAboutUs #footer {
  margin-left: 0;
  margin-right: 0;
  width: 95%;
  margin: 0 auto;
}#footer span,
#footer .copyright a {
  text-transform: uppercase;
  color: red;
  font-weight: bold;
}#footer .item {
  color: #7e7e7e;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}#footer .item .title,
#footer .item .icon {
  display: inline-block;
}#footer .item + .item {
  margin-left: 50px;
}#footer .copyright {
  font-size: 12px;
  color: #7e7e7e;
}#footer .footer-link-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}#footer .links {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  line-height: 0.9;
  font-family: "ClanPro";
}#footer .links + .social-links {
  margin-left: 25px;
}#footer .links a {
  color: inherit;
  text-decoration: inherit;
  border-bottom: 1px solid #000;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}#footer .links a:hover {
  color: var(--msnt-brand-color);
  border-color: var(--msnt-brand-color);
}#footer .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}#footer .social-links .social {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}#footer .social-links .social:hover {
  -webkit-box-shadow: inset 0 0 0 14px var(--msnt-brand-color);
  box-shadow: inset 0 0 0 14px var(--msnt-brand-color);
  border-color: var(--msnt-brand-color);
}#footer .social-links a {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 28px;
  height: 28px;
}.social-links a svg {
  width: 100%;
  height: 100%;
  padding: 5px;
  -webkit-transition: fill 0.3s linear;
  transition: fill 0.3s linear;
}#footer .social-links .social:hover a {
  color: #fff;
}#footer .social-links .social:hover svg {
  fill: #fff;
}/* PAGE NEWS */.pageNews .contentWrapper {
  /*background: #f7f7f7;*/
  background: #f5f5f5;
}.pageNews .newsItemDetail > .wrapper > .iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  margin-bottom: 20px;
}.pageNews .newsItemDetail > .wrapper > .iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}.loadingPreviousNews {
  font-family: ClanPro;
  text-align: center;
  margin: 20px 0;
  color: #949494;
}.itemsWrapper {
  padding: 0 10px;
  max-width: 1000px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
}.itemsWrapper .yearBlock {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}.newsItem {
  font-family: ClanPro;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  background: #fff;
  color: #949494;
  cursor: pointer;
  text-decoration: none;
}.newsItem .wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 10px;
}.newsItem .top {
  margin-bottom: 5px;

  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;

  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;

  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
}.newsItem .imgWrapper {
  width: 220px;
  min-height: 140px;
  position: relative;
}.newsItem .imgWrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: inset 0 0 15px -3px #999;
          box-shadow: inset 0 0 15px -3px #999;
}.newsItem img {
  width: 100%;
  display: block;
  min-height: 140px;
  -o-object-fit: cover;
     object-fit: cover;
}.newsItem .shortPreview {
  padding: 10px;
}.newsItem .top span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}.newsItem .title {
  font-size: 18px;
  color: #323232;
}.newsItem .mainText {
  display: none;
}/* END PAGE NEWS *//* PAGE NEWS DETAIL */.articleContent {
  overflow: hidden;
}.articleContent ul {
  list-style: circle outside none;
  margin: 0 0 10px 30px;
}.articleContent ul > li {
  list-style: inherit;
}.articleContent iframe {
  margin: 10px auto 20px;
  display: block;
  max-width: 100%;
  max-height: 50vw;
}.articleContent img {
  max-width: 100%;
  display: block;
  width: 100%;
  height: auto;
}.newsItemDetail {
  font-family: ClanPro;
  max-width: 800px;
  padding: 20px;
  background: #fff;
  margin: 0 auto;
}.newsItemDetail h2 {
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #000;
}.newsItemDetail .mainImg {
  display: block;
  margin-bottom: 20px;
}.newsItemDetail .info {
  overflow: hidden;
  margin-bottom: 20px;
  font-size: 14px;
}.newsItemDetail .source {
  text-decoration: none;
  color: #000;
  float: left;
}.newsItemDetail .date {
  float: right;
}.newsItemDetail .withFilmStrip .mainText {
  padding-left: 220px;
}.newsItemDetail .mainText p {
  margin: 10px 0;
  font-size: 14px;
}.newsItemDetail .mainText h3 {
  font-size: 18px;
}.newsItemDetail .filmStrip {
  width: 200px;
  float: left;
}.newsItemDetail .filmStrip .item + .item {
  margin-top: 5px;
}.newsItemDetail .filmStrip img {
  display: block;
  width: 100%;
}@media screen and (max-width: 767px) {
  .newsItemDetail .filmStrip {
    display: none;
  }

  .newsItemDetail .withFilmStrip .mainText {
    padding-left: 0;
  }

  .newsItemDetail h2 {
    font-size: 24px;
  }

  .slidesWrapper .slideControls .slideControl {
    width: 22px;
    height: 48px;
    background-size: 22px 96px;
    margin-top: -24px;
  }

  .slidesWrapper .slideControls .nextSlideBtn {
    right: 10px;
    background-position: 0 -48px;
  }
}/* END PAGE NEWS DETAIL *//* PAGE JOBS */.pageJobs #parralax_background {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 65%;
  min-height: 300px;
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.5" /><feFuncG type="linear" slope="0.5" /><feFuncB type="linear" slope="0.5" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}.pageJobs #parralax_background .item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}.pageJobs #parralax_background .item1 {
  background-image: url(../../src/img/jobs/banner.jpg);
}.pageJobs #parralax_background .item2 {
  background-image: url(../../src/img/jobs/banner2.jpg);
}.pageJobs #parralax_background .item3 {
  background-image: url(../../src/img/jobs/banner3.jpg);
}.pageJobs #parralax_background .item4 {
  background-image: url(../../src/img/jobs/banner4.jpg);
}.pageJobs #parralax_background .item5 {
  background-image: url(../../src/img/jobs/banner5.jpg);
}.pageJobs #parralax_background .item6 {
  background-image: url(../../src/img/jobs/banner6.jpg);
}.pageJobs .accordionWrapper {
  /*padding: 50px;*/
}.pageJobs .headerBanner {
  /*
    background: url(../img/jobs/banner.jpg) no-repeat center center;
    background-size: cover;
    */
  position: relative;
  z-index: 10;
}#jobs_accordion {
  /*margin-bottom: 50px;*/
}.ui-accordion .ui-accordion-header {
  margin: 0;
}.ui-accordion h2.ui-accordion-header {
  border-bottom: 1px solid #999;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: default;
  font-family: ClanPro;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  padding: 0 100px;
  line-height: 100px;
  width: 100%;
  text-transform: uppercase;
  -webkit-transition: background-color 0.5s, color 0.5s;
  transition: background-color 0.5s, color 0.5s;
  z-index: 1;
}.ui-accordion.accordionSliding {
  padding-top: 100px;
}.ui-accordion h2.ui-accordion-header span {
  position: absolute;
  top: 50%;
  right: 50px;
  margin-top: -16px;
  -webkit-transition: -webkit-transform 0.5s linear;
  transition: -webkit-transform 0.5s linear;
  transition: transform 0.5s linear;
  transition: transform 0.5s linear, -webkit-transform 0.5s linear;
}.ui-accordion h2.ui-accordion-header.ui-state-active {
  background-color: #000;
  color: #fff;
}.ui-accordion h2.ui-accordion-header.ui-state-active span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}.ui-accordion h3.ui-accordion-header {
  border-bottom: 1px solid #cdcdcd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 50px;
  padding: 30px 50px;
  font-family: "Open Sans";
  font-size: 17px;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}.ui-accordion h3.ui-accordion-header:last-of-type {
  border-bottom: none;
}.ui-accordion .ui-accordion-content {
  padding: 0;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}.ui-accordion .ui-accordion .ui-accordion-content {
  padding: 0 50px 40px;
  margin: 0 50px;
}.ui-accordion h3.ui-opened,
.ui-accordion h3.ui-state-active {
  border-bottom: none;
  background-color: #f8f8f8;
}.ui-accordion .ui-accordion h3.ui-state-active + .ui-accordion-content {
  background: #f8f8f8;
}.ui-accordion h3.ui-accordion-header {
  position: relative;
}.ui-accordion h3.ui-accordion-header:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 50px;
  width: 10px;
  height: 10px;
  margin-top: -6px;
  border-radius: 50%;
  border: 1px solid #000;
  -webkit-transition: box-shadow 0.5s linear;
  -webkit-transition: -webkit-box-shadow 0.5s linear;
  transition: -webkit-box-shadow 0.5s linear;
  transition: box-shadow 0.5s linear;
  transition: box-shadow 0.5s linear, -webkit-box-shadow 0.5s linear;
}.ui-accordion h3.ui-accordion-header.ui-opened:after,
.ui-accordion h3.ui-accordion-header.ui-state-active:after {
  -webkit-box-shadow: inset 0 0 0 5px #000;
          box-shadow: inset 0 0 0 5px #000;
}.ui-accordion .ui-accordion div.ui-opened {
  border-bottom: 1px solid #cdcdcd;
}.ui-accordion .ui-accordion div.ui-opened:last-of-type {
  border-bottom: none;
}.ui-accordion .requirementsWrapper .requirementTitle,
.ui-accordion .requirementsWrapper .requirementList,
.ui-accordion .requirementsWrapper .preText {
  font-family: "Open Sans";
}.ui-accordion .preText {
  margin-bottom: 20px;
}.ui-accordion .requirementTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 15px;
}.ui-accordion .requirementList + .requirementTitle {
  margin-top: 25px;
}.ui-accordion .requirementList {
  padding-left: 25px;
}.ui-accordion .requirementList li {
  list-style-type: disc;
}.ui-accordion .styledForm {
  margin-top: 50px;
}/* END PAGE JOBS */.subNav {
  background: #181818;
  color: #fff;
  padding: 40px 50px;
  font-family: ClanPro;
  text-transform: uppercase;
  font-size: 14px;
  overflow: hidden;
}.subNav .leftSide {
  float: left;
}.subNav .subTitle {
  color: #777;
}.subNav ul,
.subNav li {
  display: inline-block;
}.subNav li + li {
  margin-left: 10px;
}.subNav a {
  color: #fff;
  text-decoration: none;
}.subNav .rightSide {
  float: right;
}#send_cv_btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
}#send_cv_btn > span {
  position: relative;
  top: -2px;
  margin-left: 15px;
  border: 1px solid #fff;
  display: inline-block;
  padding: 3px;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}#send_cv_btn.active > span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}.subNavForm {
  padding: 40px 50px;
  background: #f8f8f8;
  overflow: hidden;
  display: none;
}#send_cv_form {
  display: none;
}.styledForm .leftSide {
  float: left;
}.styledForm .rightSide {
  float: right;
}.styledForm legend {
  font-size: 17px;
  font-weight: bold;
}.styledForm .fieldsWrapper {
  font-size: 0;
  line-height: 0;
  margin: 25px 0;
}.styledForm .field {
  vertical-align: top;
  display: inline-block;
  width: 22%;
}.styledForm .field + .field {
  margin-left: 4%;
}.styledForm .fieldsWrapper label.error {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: red;
  display: inline-block;
}.styledForm .styledBtn .error {
  padding-top: 5px;
  position: absolute;
  color: red;
  left: 0;
  right: -20px;
  top: 100%;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  text-transform: none;
  font-weight: normal;
}.styledForm input[type="text"],
.styledForm input[type="email"] {
  border: none;
  color: #000;
  background: transparent;
  font-size: 14px;
  line-height: 1.5;
  border-bottom: 2px solid #000;
  font-family: ClanPro;
  border-radius: 0;
  width: 100%;
}.styledForm .styledBtn {
  position: relative;
  padding: 10px 20px;
  border: 1px solid #000;
  display: inline-block;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  line-height: 20px;
  -webkit-transition: box-shadow 0.3s linear, color 0.3s linear;
  -webkit-transition: color 0.3s linear, -webkit-box-shadow 0.3s linear;
  transition: color 0.3s linear, -webkit-box-shadow 0.3s linear;
  transition: box-shadow 0.3s linear, color 0.3s linear;
  transition: box-shadow 0.3s linear, color 0.3s linear, -webkit-box-shadow 0.3s linear;
}.styledForm .styledBtn.error {
  border-color: red;
  color: red;
}.styledForm .styledBtn:hover {
  -webkit-box-shadow: inset 0 0 0 20px #000;
          box-shadow: inset 0 0 0 20px #000;
  color: #fff;
}.styledForm .styledBtn.sendBtn {
  color: #fff;
  background: #000;
}.styledForm .styledBtn.sendBtn * {
  cursor: pointer;
}.styledForm .styledBtn.sendBtn:hover {
  -webkit-box-shadow: inset 0 0 0 20px #fff;
          box-shadow: inset 0 0 0 20px #fff;
  color: #000;
}.styledForm .styledBtn input[type="file"],
.styledForm .styledBtn input[type="submit"] {
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}.styledForm .styledBtn input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}.styledForm .styledBtn + .styledBtn {
  margin-left: 30px;
}::-webkit-input-placeholder {
  color: #000;
}:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}:-ms-input-placeholder {
  color: #000;
}/* STYLED BLOCKS */.styledBlocksWrapper {
  overflow: hidden;
  padding: 50px;
  font-size: 0;
  line-height: 0;
}.styledBlocksWrapper .styledBlock {
  width: 22%;
  display: inline-block;
  vertical-align: top;
  margin-left: 4%;
}@media screen and (min-width: 1351px) {
  .styledBlocksWrapper .styledBlock:nth-child(4n + 1) {
    margin-left: 0;
  }

  .styledBlocksWrapper .styledBlock:nth-child(n + 5) {
    margin-top: 50px;
  }
}.styledBlocksWrapper .styledBlock img {
  display: block;
}.styledBlocksWrapper .styledBlock .title {
  margin: 25px 0 25px;
  font-size: 15px;
  line-height: 1;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
}.styledBlocksWrapper .styledBlock p {
  font-family: ClanPro;
  font-size: 14px;
  line-height: 1.5;
  color: #8c8c8c;
}/* END STYLED BLOCKS */.imagesBlockWrapper {
  padding: 50px;
  overflow: hidden;
}.imagesBlockWrapper .description {
  float: left;
  width: 45%;
  margin-right: 5%;
}.imagesBlockWrapper .title {
  margin: 0 0 25px;
  font-size: 15px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
}.imagesBlockWrapper .images {
  width: 50%;
  float: left;
}.imagesBlockWrapper .imagesWrapper {
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
}.imagesBlockWrapper .imagesWrapper .wrapper {
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}.imagesBlockWrapper .controls {
  text-align: center;
  margin-top: 10px;
  font-family: ClanPro;
}.imagesBlockWrapper .controls .count {
  display: inline-block;
  vertical-align: middle;
}.imagesBlockWrapper .controls .divider {
  margin: 0 3px;
}.imagesBlockWrapper .controls .left,
.imagesBlockWrapper .controls .right {
  cursor: pointer;
  color: #fff;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  -webkit-transition: box-shadow 0.5s linear;
  -webkit-transition: -webkit-box-shadow 0.5s linear;
  transition: -webkit-box-shadow 0.5s linear;
  transition: box-shadow 0.5s linear;
  transition: box-shadow 0.5s linear, -webkit-box-shadow 0.5s linear;
}.imagesBlockWrapper .controls .left:hover,
.imagesBlockWrapper .controls .right:hover {
  background: #fff;
  -webkit-box-shadow: inset 0 0 0 20px #000;
          box-shadow: inset 0 0 0 20px #000;
}.ui-accordion-content {
  height: auto;
}.accordion-expand-holder {
  margin: 10px 0;
}.accordion-expand-holder .open,
.accordion-expand-holder .close {
  margin: 0 10px 0 0;
}@media screen and (max-width: 1480px) {
  .infographic .item + .item {
    margin-left: 20px;
  }

  .infographic .digit {
    font-size: 70px;
  }

  .infographic .text {
    font-size: 16px;
  }
}@media screen and (max-width: 1350px) {
  .styledBlocksWrapper .styledBlock {
    width: 30%;
    margin-left: 5%;
  }

  .styledBlocksWrapper .styledBlock:nth-child(3n + 1) {
    margin-left: 0;
  }

  .styledBlocksWrapper .styledBlock:nth-child(n + 4) {
    margin-top: 50px;
  }

  /*
    .newsItem {
        width: 30%;
        margin-left: 2.5%;
    }
    */
}@media screen and (max-width: 1290px) {
  .infographic .item + .item {
    margin-left: 15px;
  }

  .infographic .digit {
    font-size: 60px;
  }

  .infographic .text {
    font-size: 14px;
  }
}@media screen and (max-width: 1000px) {
  .subNav {
    padding: 30px 20px;
    font-size: 12px;
  }

  .subNav li + li {
    margin-left: 5px;
  }

  .subNavForm {
    padding: 30px 20px;
    background: #f8f8f8;
  }
}@media screen and (max-width: 1320px) {
  .slideContent .titleWrapper {
    font-size: 80px;
  }

  .slideAutosportInternational .titleWrapper {
    font-size: 72px;
  }
}@media screen and (max-width: 1150px) {
  .slideContent .titleWrapper {
    font-size: 71px;
  }

  .slideAutosportInternational .titleWrapper {
    font-size: 62px;
  }

  .slideContent .slogan {
    font-size: 34px;
  }

  .infographic .item .imgWrapper {
    margin-right: 0;
  }

  .infographic .item .imgWrapper svg {
    /*width: 50px;*/
    /*transform: scale(0.7, 0.7);*/
    /*width: 70%;*/
  }

  /*        .infographic .item.onDemandVideo .imgWrapper scale {
            width: 80%;
        }*/
  .infographic .digit {
    font-size: 60px;
  }

  .infographic .text {
    font-size: 14px;
  }
}@media screen and (max-width: 1150px) and (orientation: portrait) {
  .slideContent .titleWrapper {
    margin: 35px 0 35px;
    font-size: 57px;
  }

  .slideContent .slogan {
    font-size: 28px;
  }

  .infographic {
    margin-bottom: 35px;
  }

  .infographic .item .imgWrapper {
    margin-right: 0;
    /*transform: scale(0.75) translateY(12.5%);*/
  }

  .infographic .item .imgWrapper img {
    width: 65%;
  }

  .infographic .item.onDemandVideo .imgWrapper img {
    width: 75%;
  }

  .infographic .digit {
    font-size: 48px;
  }

  .infographic .text {
    font-size: 12px;
  }

  .infographic .item + .item {
    margin-left: 15px;
  }

  .infographicVertical .item + .item {
    margin-left: 60px;
  }
}@media screen and (max-width: 1024px) {
  /*
    .slidesWrapper .imagesNavigation span {
        width: 24px;
        height: 24px;
    }
    .slidesWrapper .imagesNavigation span.current{
        width: 34px;
        height: 34px;
    }
    .slidesWrapper .current .imagesNavigation span.current{
        box-shadow: inset 0 0 0 17px red;
        -webkit-transition: box-shadow 9.5s linear,
            height 0.3s linear,
            width 0.3s linear;
        transition: box-shadow 9.5s linear,
            height 0.3s linear,
            width 0.3s linear;
    }
    */
  /*
    .newsItem {
        margin-bottom: 30px;
    }
    */
  .ui-accordion .ui-accordion .ui-accordion-content,
  .ui-accordion h2.ui-accordion-header,
  .ui-accordion h3.ui-accordion-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}@media screen and (max-width: 1020px) {
  .slidesWrapper .slideMotorsport {
    background-position: 70% center;
  }

  .slideContent .bigLogo {
    margin-bottom: 0;
  }

  .infographic {
    margin-bottom: 50px;
  }

  .infographic .item {
    width: auto;
    min-width: 150px;
  }

  .slideContent .titleWrapper h2 {
    font-size: 64px;
  }

  .slideMotorsportstats .titleWrapper h2 {
    font-size: 52px;
  }

  .slideAutosportInternational .titleWrapper h2 {
    font-size: 56px;
  }

  /*
    .newsItem {
        width: 43%;
        margin-left: 4.7%;
        margin-bottom: 40px;
    }
    */
}@media screen and (min-width: 1024px) {
  .infographic {
    white-space: nowrap;
  }

  .slideContent p {
    display: inline-block;
  }
}@media screen and (max-width: 1024px) and (max-height: 768px) {
  .slideContent .bigLogo {
    max-width: 700px;
  }

  .slideAutoMotor .infographic .item + .item {
    margin-left: 15px;
  }

  .infographic {
    white-space: nowrap;
    margin-bottom: 50px;
  }
}@media screen and (max-width: 1200px) {
  .slidesWrapper .slideMotorsport .slideBackgrounds .bg2 {
    background-position: 85% center;
  }
}@media screen and (max-width: 920px) and (orientation: landscape) {
  .slideContent .titleWrapper h2 {
    font-size: 50px;
  }

  .slideAutosportInternational .slideContent .titleWrapper h2 {
    font-size: 32px;
  }

  .slideAutosportInternational .titleWrapper {
    font-size: 45px;
  }
}@media screen and (max-height: 768px) and (orientation: landscape) {
  .slidesWrapper .slideMotorsport .bg0 {
    background-size: auto 115%;
    background-position: 83% 30%;
  }

  .slidesWrapper .slideContent .titleWrapper {
    margin: 8px 0;
  }
}@media screen and (max-width: 950px) {
  .oneContactWrapper {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    vertical-align: top;
  }
}@media screen and (max-height: 850px) {
  .slideContent .bigLogo {
    /*max-width: 700px;*/
    margin: 0;
  }

  /*    .infographic {
        margin-top: 40px;
    }*/

  #overlay .overlayContent .wrapper {
    max-height: 400px;
  }
}@media screen and (max-width: 767px) {
  .infographic .item {
    min-width: 120px;
  }
}@media screen and (max-width: 766px) {
  /*
    .newsItem {
        margin-bottom: 40px;
    }
    */
  .slideContent p {
    font-size: 19px;
  }
}@media screen and (max-width: 660px) {
  .slideContent .titleWrapper h2 {
    font-size: 32px;
  }

  .slidesWrapper .slideMotorsportExperiences .titleWrapper span {
    letter-spacing: 0.6px;
  }
}@media screen and (max-height: 620px), (max-width: 767px) {
  .slideContent .bigLogo {
    margin-bottom: 0;
  }

  .slideContent .slogan {
    font-size: 21px;
  }

  .slideContent .bigLogo {
    max-width: 420px;
  }

  .slideContent p {
    font-size: 13px;
  }

  .slideContent .badge {
    margin-top: 5px;
    font-size: 14px;
  }

  .slideContent .titleWrapper {
    font-size: 36px;
    margin: 20px 0;
  }

  .infographic {
    margin-bottom: 10px;
  }

  .infographic .digit {
    font-size: 36px;
  }

  .infographic .text {
    font-size: 11px;
  }

  .infographic .item + .item {
    margin-left: 20px;
  }
}@media screen and (max-height: 370px), (max-width: 600px) {
  .slideContent .bigLogo {
    margin-bottom: 0;
  }

  .slideContent .slogan {
    font-size: 16px;
    line-height: 1;
  }

  .slideContent .badge {
    font-size: 12px;
  }

  .slideContent .bigLogo {
    width: 320px;
  }

  .slideContent p {
    font-size: 10px;
    line-height: 1.2;
    display: inline;
  }

  .infographic .text {
    font-size: 8px;
  }

  .slideContent .titleWrapper {
    font-size: 32px;
    /*margin: 10px 0;*/
    margin: 0;
  }

  .infographic .digit {
    font-size: 36px;
  }

  .infographic {
    margin-top: 0;
  }

  .infographic + div {
    margin: 15px auto 0;
    max-width: 90%;
    line-height: 1;
  }

  .infographic .item + .item {
    margin-left: 10px;
  }
}@media screen and (max-width: 450px) {
  .slideContent .titleWrapper h2 {
    font-size: 32px;
    line-height: 1;
  }

  .slidesWrapper .slideMotorsportExperiences .titleWrapper h2 {
    font-size: 0.6em;
  }

  .slideGaming .slideContent .titleWrapper h2,
  .slideAmalgam .slideContent .titleWrapper h2,
  .slideMotorsportstats .slideContent .titleWrapper h2 {
    font-size: 24px;
  }

  .menuVisible #menu-fade {
    display: none;
  }
}@media screen and (max-width: 400px) {
  .slideContent .bigLogo {
    max-width: 250px;
  }
}@media screen and (max-width: 500px) and (orientation: portrait) {
  .slideMotorsport .infographic .item,
  .slideMotor1 .infographic .item {
    margin-top: 10px;
    margin-right: 10px;
  }

  .slideAutoMotor .infographic .item {
    width: 140px;
    margin-right: 5px;
    margin-top: 10px;
  }

  .slideAutoMotor .infographic .item + .item,
  .slideMotorsport .infographic .item + .item,
  .slideMotor1 .infographic .item + .item {
    margin-left: 0;
  }
}@media screen and (max-width: 1150px) {
  .slideContent p .newLineTablet {
    display: inline;
  }
}@media screen and (max-width: 768px) {
  .slideContent p .newLine {
    display: inline;
  }
}@media screen and (max-width: 767px) {
  .slidesWrapper .slideControls {
    display: none;
  }
}@media screen and (max-width: 420px) {
  .slide .infographic .item {
    width: 80%;
    display: block;
    margin: 0 auto;
  }

  .slide .infographic .item + .item {
    margin: 10px auto 0;
  }

  .slideGaming .infographic .item + .item,
  .slideAmalgam .infographic .item + .item,
  .slideRideApart .infographic .item + .item {
    margin-top: 0;
  }

  .slideMotor1 .infographic .item,
  .slideAutoMotor .infographic .item,
  .slideF1Racing .infographic .item,
  .slideMotorsport .infographic .item {
    width: 100%;
    text-align: center;
  }

  .slideMotorsportTv .infographic .item {
    width: 100%;
  }

  .slideMotorsportTv .infographic .digit {
    display: inline-block;
  }

  .slideFerrariChat .infographic .text {
    white-space: normal;
  }

  .slideAmalgam .infographic .item {
    width: 100%;
    text-align: center;
  }

  .slideFerrariChat .infographic .item {
    width: 100%;
    text-align: center;
  }

  .slideGiorgioPiola .infographic .item {
    width: 100%;
    text-align: center;
  }

  .slideGaming .slideContent p,
  .slideFerrariChat .slideContent p {
    width: 85%;
    margin: 0 auto;
    text-align: center;
  }

  .infographic .text {
    font-size: 12px;
    line-height: 1.2;
    display: inline-block;
    text-align: left;
  }

  .infographic .digit {
    font-size: 52px;
  }

  .infographicVertical .item .imgWrapper {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0;
  }

  .slideContent .slogan {
    font-size: 36px;
    /*margin-bottom: 20px;*/
    margin-bottom: 0;
  }

  .slideContent .slogan span {
    display: block;
  }

  .slideContent .bigLogo {
    display: none;
  }

  .slideContent .badge,
  .slideContent p {
    font-size: 14px;
    line-height: 1.5;
  }

  .slidesWrapper .slideContent {
    padding: 0 10px;
    top: calc(50% + 20px);
  }

  .slideContent .titleWrapper {
    margin: 20px 0;
    /*font-size: 24px;*/
  }

  .slideContent p .newLine {
    display: inline-block;
  }

  .slideMotorsportstats .infographic .text,
  .slideMotorstore .infographic .text,
  .slideMotorsportstats .infographic .imgWrapper,
  .slideMotorstore .infographic .imgWrapper,
  .slideAmalgam .infographic .imgWrapper,
  .slideAmalgam .infographic .text,
  .slideGiorgioPiola .infographic .imgWrapper,
  .slideGiorgioPiola .infographic .text,
  .slideFerrariChat .infographic .imgWrapper,
  .slideFerrariChat .infographic .text,
  .slideGaming .infographic .imgWrapper,
  .slideGaming .infographic .text,
  .slideRideApart .infographic .imgWrapper,
  .slideRideApart .infographic .text,
  .slideAutosport .infographic .imgWrapper,
  .slideAutosport .infographic .text,
  .slideAutosportMagazine .infographic .imgWrapper,
  .slideAutosportMagazine .infographic .text,
  .slideAutosportInternational .infographic .imgWrapper,
  .slideAutosportInternational .infographic .text,
  .slideLatImages .infographic .imgWrapper,
  .slideLatImages .infographic .text,
  .slideFormulaE .infographic .imgWrapper,
  .slideFormulaE .infographic .text,
  .slideAutosportAwards .infographic .imgWrapper,
  .slideAutosportAwards .infographic .text,
  .slideInsideEvs .infographic .imgWrapper,
  .slideInsideEvs .infographic .text,
  .forix .infographic .imgWrapper,
  .forix .infographic .text,
  .motorgt .infographic .imgWrapper,
  .motorgt .infographic .text,
  .gppredictor .infographic .imgWrapper,
  .gppredictor .infographic .text,
  .motorsportjobs .infographic .imgWrapper,
  .motorsportjobs .infographic .text,
  .autoclassic .infographic .imgWrapper,
  .autoclassic .infographic .text,
  .motormarket .infographic .imgWrapper,
  .motormarket .infographic .text,
  .dukevideo .infographic .imgWrapper,
  .dukevideo .infographic .text {
    vertical-align: middle;
  }
}/*
@media screen and (max-height: 400px),
@media screen and (max-width: 480px) {
    
}
*/@media screen and (max-height: 500px) {
  #overlay .overlayContent .wrapper {
    max-height: 200px;
  }

  .GoogleMap {
    height: 300px;
  }
}@media screen and (max-height: 400px) {
  .GoogleMap {
    height: 200px;
  }
}@media screen and (max-width: 380px) {
  .infographic .text {
    white-space: normal;
  }
}@media screen and (max-width: 320px) {
  /* .slidesWrapper .slideContent {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    } */

  /* .slidesWrapper .slideAutoMotor .slideContent,
    .slidesWrapper .slideMotorsport .slideContent {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    } */
  .slideContent p {
    font-size: 11px;
  }

  .slide .infographic .item {
    width: 100% !important;
    text-align: center;
  }

  .slideContent .slogan {
    font-size: 32px;
  }

  .infographic .digit {
    font-size: 38px;
  }

  .infographic .text {
    font-size: 9px;
  }

  .slideContent .slogan {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .slideContent .titleWrapper {
    font-size: 24px;
  }

  .slideContent .slogan span {
    display: inline;
  }
}@media screen and (max-width: 320px) and (max-height: 450px) {
  .slide .infographic .item + .item {
    margin-top: 8px;
  }

  .infographic {
    margin-top: 8px;
  }
}@media screen and (max-width: 760px) {
  .subNav .leftSide,
  .subNav .rightSide {
    float: none;
  }

  .subNav .rightSide {
    margin-top: 20px;
  }

  .styledBlocksWrapper .styledBlock {
    width: 46%;
  }

  .styledBlocksWrapper .styledBlock + .styledBlock {
    margin-left: 0;
  }

  .styledBlocksWrapper .styledBlock:nth-child(2n) {
    margin-left: 8%;
  }

  .styledBlocksWrapper .styledBlock:nth-child(n + 3) {
    margin-top: 30px;
  }
}@media screen and (max-width: 720px) {
  /*.pageJobs .accordionWrapper,*/
  .imagesBlockWrapper,
  .styledBlocksWrapper {
    padding: 20px;
  }

  #footer {
    margin-left: 20px;
    margin-right: 20px;
  }

  .ui-accordion h2.ui-accordion-header {
    font-size: 23px;
    line-height: 54px;
    padding: 0 65px 0 15px;
  }

  .ui-accordion h3.ui-accordion-header {
    padding: 15px 40px 15px 15px;
    margin: 0 20px;
    font-size: 14px;
  }

  .ui-accordion h2.ui-accordion-header span {
    right: 25px;
  }

  .ui-accordion .ui-accordion .ui-accordion-content {
    padding: 0 15px 20px;
    margin: 0 20px;
  }

  .ui-accordion h3.ui-accordion-header:after {
    right: 25px;
  }

  .imagesBlockWrapper .description {
    width: 100%;
    margin: 0;
    float: none;
  }

  .imagesBlockWrapper .images {
    float: none;
    width: 100%;
    margin-top: 20px;
  }

  .styledForm .field {
    width: 46%;
  }

  .styledForm .field + .field {
    margin-left: 0;
  }

  .styledForm .field:nth-child(n + 3) {
    margin-top: 20px;
  }

  .styledForm .field:nth-child(2n + 2) {
    margin-left: 4%;
  }
}@media screen and (max-width: 550px) {
  .newsItem {
    display: block;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }

  .newsItem .wrapper {
    grid-template-columns: 1fr;
  }

  .newsItem .imgWrapper {
    width: auto;
    float: none;
    margin: 0;
  }

  .newsItem img {
    width: 100%;
    margin: 0;
    float: none;
  }

  #footer {
    margin: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 20px 2.5%;
  }

  #footer .links {
    margin-right: 0;
  }

  #footer .links + .social-links {
    margin-left: 20px;
  }

  #footer .copyright {
    text-align: left;
    margin-right: 10px;
  }

  /* .slidesWrapper .slideContent {
        top: 60px;
    } */
}@media screen and (max-width: 550px) {
  #footer .item {
    display: block;
  }

  #footer .item + .item {
    margin-left: 0;
    margin-top: 10px;
  }

  .requirementsWrapper .styledForm .styledBtn + .styledBtn {
    margin-left: 10px;
  }
}@media screen and (max-width: 480px) {
  .headerBanner h1 {
    font-size: 24px;
  }

  .headerBanner p {
    font-size: 16px;
  }
}@media screen and (max-width: 479px) {
  .styledForm .field {
    width: 100%;
    margin: 0;
  }

  .styledForm .field + .field {
    margin-top: 20px;
    margin-left: 0;
  }

  .styledForm .leftSide {
    float: none;
  }

  .styledForm .rightSide {
    margin-top: 20px;
  }

  .styledForm .styledBtn {
    padding: 5px 10px;
    font-size: 13px;
  }

  .styledForm .styledBtn + .styledBtn {
    margin-left: 20px;
  }

  .styledBlocksWrapper .styledBlock {
    width: 100%;
  }

  .styledBlocksWrapper .styledBlock + .styledBlock {
    margin-left: 0;
    margin-top: 30px;
  }
}@media screen and (max-width: 400px) {
  .requirementsWrapper .styledForm .leftSide .styledBtn:first-child {
    margin-right: 50px;
  }

  .requirementsWrapper .styledForm .styledBtn + .styledBtn {
    margin-top: 10px;
    margin-left: 0;
  }
}@media screen and (max-width: 880px) {
  .motorsportjobs .titleWrapper h2 {
    font-size: 50px;
  }
}@media screen and (max-width: 640px) {
  .motorsportjobs .titleWrapper h2 {
    font-size: 32px;
  }
}@media screen and (max-width: 410px) {
  .motorsportjobs .titleWrapper h2 {
    font-size: 24px;
  }
}.ms-mt-10 {
  margin-top: 10px;
}.ms-display-t,
.ms-display-d {
  display: none !important;
}.ms-display-m {
  display: block !important;
}.ms-display-ib {
  display: inline-block;
}@media screen and (min-width: 768px) {
  .ms-display-m,
  .ms-display-d {
    display: none !important;
  }

  .ms-display-t {
    display: block !important;
  }
}@media screen and (min-width: 1024px) {
  .ms-display-t,
  .ms-display-m {
    display: none !important;
  }

  .ms-display-d {
    display: block !important;
  }
}@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}@keyframes jump {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}@-webkit-keyframes jump-rotated {
  0% {
    -webkit-transform: rotate(180deg) translateY(0);
            transform: rotate(180deg) translateY(0);
  }

  50% {
    -webkit-transform: rotate(180deg) translateY(10px);
            transform: rotate(180deg) translateY(10px);
  }

  100% {
    -webkit-transform: rotate(180deg) translateY(0);
            transform: rotate(180deg) translateY(0);
  }
}@keyframes jump-rotated {
  0% {
    -webkit-transform: rotate(180deg) translateY(0);
            transform: rotate(180deg) translateY(0);
  }

  50% {
    -webkit-transform: rotate(180deg) translateY(10px);
            transform: rotate(180deg) translateY(10px);
  }

  100% {
    -webkit-transform: rotate(180deg) translateY(0);
            transform: rotate(180deg) translateY(0);
  }
}
/*# sourceMappingURL=main_layout113.css.map */