.wow-alert-overlay {
    position:fixed;
    top:0;
    left:0;
    background:rgba(0, 0, 0, .6);
    z-index:500;
    width:100%;
    height:100%;
    z-index: 999999;
}.wow-alert-content {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    background:#FFF;
    border-radius:3px;
    -khtml-border-radius:3px;
    position:fixed;
    z-index:99999999;
    min-width:200px;
    min-height:50px;
    max-width:75%;
    padding: 15px 20px;
    left: 50%;
    top: 50%;
}.wow-alert-content p {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #333;
    margin: 10px 0 20px 0;
    font-weight: bold;
    text-align: center;
}.wow-alert-content a {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    font-family: Helvetica, Arial, sans-serif;
    border-radius:3px;
    -khtml-border-radius:3px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    margin: 10px;
    color: #F6FAF7;
    display: block;
    margin: 0 auto;
    width: 100px;
    background: #579959;
    text-decoration: none;
    height: 30px;
    line-height: 30px;
}.wow-alert-content a:hover {
    background: #477F4A;
}
/*# sourceMappingURL=wow-alert.css.map */