.ms-fade-in-text {
    background-color: #f0f0f0;
}#ms-fade-in-text_wrapper {
    background-color: transparent;
    position: relative;
    padding: 80px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 35px;
}#ms-fade-in-text_wrapper:before,
#ms-fade-in-text_wrapper:after {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    border: 10px solid #c9c9c9;
    position: absolute;
}#ms-fade-in-text_wrapper:before {
    border-bottom-width: 0;
    border-right-width: 0;
    top: 40px;
    left: 0;
}#ms-fade-in-text_wrapper:after {
    border-top-width: 0;
    border-left-width: 0;
    right: 0;
    bottom: 40px;
}.ms-fade-in-text h2 {
    font-size: 38px;
    font-family: "Blair-Medium";
    text-align: center;
}.ms-fade-in-text-animated {
    text-align: center;
    opacity: 0;
    font-size: 24px;
    font-family: 'Nexa-Regular';
    padding: 0 20px;
}.ms-fade-in-text-animated span {
    opacity: 0;
    -webkit-transition: opacity 1.2s linear;
    transition: opacity 1.2s linear;
}.ms-fade-in-text-animated span.animated {
    opacity: 1;
}@media screen and (min-width: 768px) {

    #ms-fade-in-text_wrapper:before,
    #ms-fade-in-text_wrapper:after {
        width: 40px;
        height: 40px;
    }

    #ms-fade-in-text_wrapper {
        padding: 60px 40px 80px 40px;
    }
}
/*# sourceMappingURL=fade-in-text.css.map */