@media screen and (min-width: 1024px) {
  :root {
    --msnt-header-height: 38px;

    --msnt-header-padding-left: 20px;
    --msnt-header-padding-right: 20px;
    --msnt-header-padding-top: 10px;
    --msnt-header-padding-bottom: 10px;
  }
}
/*# sourceMappingURL=variables.css.map */